import React from 'react'
import * as R from 'ramda'
import { Icon, Table, Progress } from 'semantic-ui-react'

import type { Cluster, Instance, Service } from '../../ducks/cluster'
import './ProcessingClusterRow.css'

type Props = { idx: number; cluster: Cluster }

const ProcessingClusterRow = (props: Props): JSX.Element => {
  const { idx, cluster } = props
  const jupyterNotebook = R.find(R.propEq('name', 'jupyter-notebook'), cluster.services)
  const totalInstances = R.propOr<number, Service | undefined, number>(
    0,
    'numberOfInstances',
    jupyterNotebook
  )

  const getWeight = (instance: Instance) => {
    if (instance.status !== 'ready') {
      return 0.5
    }
    return 1.0
  }
  const weightedInstanceCount = R.compose(
    R.sum,
    R.map(getWeight)
  )(jupyterNotebook ? jupyterNotebook.instances : [])

  const percent =
    cluster.status === 'creating'
      ? Math.floor((weightedInstanceCount / totalInstances) * 100)
      : Math.floor(((totalInstances - weightedInstanceCount) / totalInstances) * 100)

  return (
    <Table.Row className="processing" verticalAlign="middle">
      <Table.Cell className="cluster-name" content={cluster.name} id={`cluster-name-${idx}`} />
      <Table.Cell className="status">
        <Icon name="circle" color="grey" />
        {cluster.status}
      </Table.Cell>
      <Table.Cell className="progress" colSpan="3">
        <Progress
          className="ClusterRow"
          percent={percent}
          progress
          active
          autoSuccess
          color="grey"
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default ProcessingClusterRow
