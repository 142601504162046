import { PayloadAction } from '@reduxjs/toolkit'
import { createLogic } from 'redux-logic'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
  changePassword,
  changePasswordSucceeded,
  changePasswordFailed
} from '../ducks/change_password'
import type { LogicDependency, RootState } from '../redux/store'
import type { ErrorPayload, ErrorType } from '../types'
import errorObject from './helper'

const changePasswordLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<{ currentPassword: string; newPassword: string }>
>({
  type: changePassword.type,

  processOptions: {
    dispatchReturn: true,
    successType: () => changePasswordSucceeded(),
    failType: (error: Error | undefined) => changePasswordFailed(error as ErrorType)
  },

  process({ action, webClient, apiURL }): Observable<void> {
    const { currentPassword, newPassword } = action.payload
    const headers = { 'Content-Type': 'application/json' }
    const body = {
      oldPassword: currentPassword,
      newPassword
    }
    return webClient.post<void>(apiURL('change-password'), body, headers).pipe(
      catchError((error: ErrorPayload) => {
        throw errorObject(error)
      })
    )
  }
})

export default changePasswordLogic
