import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon, Menu } from 'semantic-ui-react'

import './Header.css'

type Props = {
  username: string | null
  logout: () => void
}

const Header = ({ username, logout }: Props): JSX.Element => {
  const handleLogout = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    logout()
  }

  return (
    <Menu inverted size="small" className="header">
      <Link to="/" id="header-top-link" className="item header-logo">
        AAPF
      </Link>
      {username && (
        <Link to="/" id="header-aacluster" className="item header-logo">
          AACluster
        </Link>
      )}
      {username && (
        <Menu.Item position="right">
          <Dropdown
            button
            compact
            id="user-menu"
            icon={<Icon name="user" fitted />}
            pointing="top right"
            text={username}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                className="header-menu"
                text="User Profile"
                id="link-user-profile"
                as={Link}
                to="/user-profile/api-token"
              />
              <Dropdown.Divider />
              <Dropdown.Item id="logout-item" text="Logout" onClick={(e) => handleLogout(e)} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      )}
    </Menu>
  )
}

export default Header
