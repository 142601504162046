import React from 'react'
import { Grid, Header } from 'semantic-ui-react'

import './ClusterImageDescription.css'

type Props = {
  description: string
}

const ClusterImageDescription = (props: Props): JSX.Element => {
  const { description } = props

  return (
    <Grid columns={1} padded divided className="image-description">
      <Grid.Column>
        <Header as="h5">Description</Header>
        {description}
      </Grid.Column>
    </Grid>
  )
}

ClusterImageDescription.defaultProps = {}

export default ClusterImageDescription
