import * as R from 'ramda'
import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import ClusterRow from './ClusterRow'
import ProcessingClusterRow from './ProcessingClusterRow'

import type { Cluster, Clusters } from '../../ducks/cluster'
import './CreateTable.css'

type Props = {
  clusters?: Clusters
  isLoading?: boolean
  showAlerts: (clusterName: string) => void
  showDeleteConfirmation: (clusterName: string) => void
}

const ClusterTable = (props: Props): JSX.Element => {
  const { clusters, isLoading, showAlerts, showDeleteConfirmation } = props
  const renderLoading = () => (
    <Table.Row>
      <Table.Cell colSpan="5" className="message" id="loading-cluster-row">
        <Icon loading name="spinner" />
        Loading...
      </Table.Cell>
    </Table.Row>
  )

  const renderNoCluster = () => (
    <Table.Row id="no-cluster-row">
      <Table.Cell colSpan="5" className="message">
        No clusters found.
      </Table.Cell>
    </Table.Row>
  )

  const renderClusterRow = (cluster: Cluster, idx: number) => {
    if (cluster.status === 'created') {
      return (
        <ClusterRow
          idx={idx}
          key={cluster.name}
          cluster={cluster}
          showAlerts={showAlerts}
          showDeleteConfirmation={showDeleteConfirmation}
        />
      )
    }
    return <ProcessingClusterRow idx={idx} key={cluster.name} cluster={cluster} />
  }

  const renderTableBody = () => {
    if (isLoading) {
      return renderLoading()
    }
    if (R.isEmpty(clusters)) {
      return renderNoCluster()
    }
    const mapIndexed = R.addIndex<Cluster, JSX.Element>(R.map)
    return mapIndexed(
      (cluster: Cluster, idx: number) => renderClusterRow(cluster, idx),
      clusters ?? []
    )
  }

  return (
    <Table id="cluster-table" celled fixed compact="very">
      <Table.Header>
        <Table.Row id="cluster-table-tr">
          <Table.HeaderCell className="cluster-name" content="Cluster Name" width={7} />
          <Table.HeaderCell className="status" content="Status" width={3} />
          <Table.HeaderCell className="created-at" content="Created At" width={3} />
          <Table.HeaderCell className="access-point" content="Access Point" width={3} />
          <Table.HeaderCell className="actions" width={2} collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderTableBody()}</Table.Body>
    </Table>
  )
}

ClusterTable.defaultProps = {
  isLoading: false,
  clusters: []
}

export default ClusterTable
