import * as R from 'ramda'
import React, { MouseEvent } from 'react'
import { Button, List, Message, Modal } from 'semantic-ui-react'

type Props = {
  clusterName?: string
  alerts?: {
    jupyterNotebook?: Array<string>
  }
  isOpened?: boolean
  close: () => void
}

const ClusterAlertModal = ({ clusterName, alerts, isOpened, close }: Props): JSX.Element => {
  const jupyterNotebook = R.propOr<string[], unknown, string[]>(
    [] as string[],
    'jupyterNotebook',
    alerts
  )
  const handleClose = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    close()
  }

  const renderAlert = (header: string, alertMsgs: Array<string>) => (
    <List>
      <List.Header>{header}</List.Header>
      {R.map((alert: string) => <List.Item key={alert} content={alert} />)(alertMsgs)}
    </List>
  )

  return (
    <Modal closeOnDimmerClick={false} open={isOpened} onClose={handleClose}>
      <Modal.Header>{`Alert in ${clusterName || ''}`}</Modal.Header>
      <Modal.Content>
        <Message error>
          {jupyterNotebook &&
            jupyterNotebook.length > 0 &&
            renderAlert('Jupyter Notebook', jupyterNotebook)}
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={handleClose} />
      </Modal.Actions>
    </Modal>
  )
}

ClusterAlertModal.defaultProps = {
  clusterName: null,
  alerts: {},
  isOpened: false
}

export default ClusterAlertModal
