import React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ClusterTableComponent from '../../components/Cluster/ClusterTable'
import {
  showDeleteConfirmation as showDeleteConfirmationAction,
  showAlerts as showAlertsAction
} from '../../ducks/cluster'
import type { Clusters, ClusterState } from '../../ducks/cluster'

type Props = {
  clusters: Clusters
  isLoading: boolean
  showAlerts: (clusterName: string) => void
  showDeleteConfirmation: (clusterName: string) => void
}

const ClusterTable = ({ clusters, isLoading, showAlerts, showDeleteConfirmation }: Props) => (
  <ClusterTableComponent
    clusters={clusters}
    isLoading={isLoading}
    showAlerts={showAlerts}
    showDeleteConfirmation={showDeleteConfirmation}
  />
)

const mapStateToProps = (state: { cluster: ClusterState }) => ({
  clusters: state.cluster.clusters,
  isLoading: state.cluster.isFetching
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showAlerts(clusterName: string) {
    dispatch(showAlertsAction(clusterName))
  },
  showDeleteConfirmation(clusterName: string) {
    dispatch(showDeleteConfirmationAction(clusterName))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterTable)
