import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import authReducer from '../ducks/auth'
import clusterReducer from '../ducks/cluster'
import clusterCreationReducer from '../ducks/cluster_creation'
import apiTokenFetchReducer from '../ducks/api_token_fetch'
import apiTokenCreateReducer from '../ducks/api_token_create'
import apiTokenRevokeReducer from '../ducks/api_token_revoke'
import changePasswordReducer from '../ducks/change_password'
import imageReducer from '../ducks/image'
import perfTypeReducer from '../ducks/perf_type'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function configureRootReducer(history: History<unknown>) {
  return combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    cluster: clusterReducer,
    clusterCreation: clusterCreationReducer,
    apiTokenFetch: apiTokenFetchReducer,
    apiTokenCreate: apiTokenCreateReducer,
    apiTokenRevoke: apiTokenRevokeReducer,
    changePassword: changePasswordReducer,
    image: imageReducer,
    perfType: perfTypeReducer
  })
}
