import createDebug from 'debug'
import { push } from 'connected-react-router'
import { Observable, interval } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { createLogic } from 'redux-logic'
import type { Dispatch } from 'redux'
import type { PayloadAction } from '@reduxjs/toolkit'
import errorObject from './helper'
import {
  fetchClusterSucceeded,
  fetchClusterList,
  fetchClusterListSucceeded,
  fetchClusterFailed,
  deleteClusterAccepted,
  deleteClusterRejected,
  pollClusterList,
  cancelPollClusterList,
  deleteCluster,
  fetchCluster
} from '../ducks/cluster'
import {
  createClusterAccepted,
  createClusterRejected,
  createCluster,
  CreateClusterParams
} from '../ducks/cluster_creation'
import type { Cluster, Clusters } from '../ducks/cluster'
import type { LogicDependency, RootState } from '../redux/store'
import type { ErrorPayload, ErrorType } from '../types'

const debug = createDebug('aapf:cluster')

export const pollClusterListLogic = createLogic<RootState, LogicDependency, string, PayloadAction>({
  type: pollClusterList.type,
  cancelType: cancelPollClusterList.type,
  warnTimeout: 0,

  process(
    {
      pollingInterval,
      cancelled$,
      rxScheduler // it can be replaced for test
    },
    dispatch: Dispatch,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    done
  ): void {
    // this logic never finishes until cancel because done() will not be called
    debug('start poll clusters')
    const subscriber = interval(pollingInterval, rxScheduler).subscribe(() =>
      dispatch(fetchClusterList(false))
    )

    cancelled$.subscribe(() => {
      debug('stop poll clusters')
      subscriber.unsubscribe()
    })
  }
})

export const fetchClusterLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<{ clusterName: string; fetching: boolean }>
>({
  type: fetchCluster.type,
  latest: true,

  processOptions: {
    dispatchReturn: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    successType: (apiResult: any) => fetchClusterSucceeded(apiResult),
    failType: (error: Error | undefined) => fetchClusterFailed(error as ErrorType)
  },

  process({ action, webClient, apiURL }): Observable<{ aacluster: Cluster }> {
    debug('fetch cluster')
    const headers = { 'Content-Type': 'application/json' }
    const encodedClusterName = encodeURIComponent(action.payload.clusterName)
    return webClient
      .get<{ aacluster: Cluster }>(apiURL(`aaclusters/${encodedClusterName}`), headers, true)
      .pipe(
        catchError((error: ErrorPayload) => {
          throw errorObject(error)
        })
      )
  }
})

export const fetchClusterListLogic = createLogic<RootState, LogicDependency, string, PayloadAction>(
  {
    type: fetchClusterList.type,
    latest: true,

    processOptions: {
      dispatchReturn: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      successType: (apiResult: any) => fetchClusterListSucceeded(apiResult),
      failType: (error: Error | undefined) => fetchClusterFailed(error as ErrorType)
    },

    process({ webClient, apiURL }): Observable<{ aaclusters: Clusters }> {
      debug('fetch clusters')
      const headers = { 'Content-Type': 'application/json' }
      return webClient.get<{ aaclusters: Clusters }>(apiURL('aaclusters'), headers, true).pipe(
        catchError((error: ErrorPayload) => {
          throw errorObject(error)
        })
      )
    }
  }
)

export const refreshClusterListLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction
>({
  type: new RegExp('(create|delete)ClusterAccepted'),

  processOptions: {
    dispatchReturn: true
  },

  process() {
    debug('refresh clusters')
    return fetchClusterList(true)
  }
})

export const createClusterLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<CreateClusterParams>
>({
  type: createCluster.type,

  process({ action, webClient, apiURL }, dispatch: Dispatch, done: () => void): void {
    debug('create cluster')
    const headers = { 'Content-Type': 'application/json' }
    const clusterInfo = action.payload
    const encodedClusterName = encodeURIComponent(clusterInfo.clusterName)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { clusterName, ...bodyParams } = clusterInfo

    webClient.put(apiURL(`aaclusters/${encodedClusterName}`), bodyParams, headers).subscribe({
      next() {
        dispatch(push('/'))
        dispatch(createClusterAccepted())
        done()
      },
      error(error: ErrorPayload) {
        dispatch(createClusterRejected(errorObject(error)))
        done()
      }
    })
  }
})

export const deleteClusterLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<string>
>({
  type: deleteCluster.type,

  processOptions: {
    dispatchReturn: true,
    successType: () => deleteClusterAccepted(),
    failType: (error: Error | undefined) => deleteClusterRejected(error as ErrorType)
  },

  process({ action, webClient, apiURL }): Observable<void> {
    debug('delete cluster')
    const headers = { 'Content-Type': 'application/json' }
    const encodedClusterName = encodeURIComponent(action.payload)
    return webClient.delete<void>(apiURL(`aaclusters/${encodedClusterName}`), headers).pipe(
      catchError((error: ErrorPayload) => {
        throw errorObject(error)
      })
    )
  }
})
