import createDebug from 'debug'
import { createLogic } from 'redux-logic'
import type { Dispatch } from 'redux'

import type { PayloadAction } from '@reduxjs/toolkit'
import { fetchImageList, fetchImageListSucceeded, fetchImageListFailed } from '../ducks/image'
import type { Image } from '../ducks/image'
import type { LogicDependency, RootState } from '../redux/store'
import type { ErrorType } from '../types'

const debug = createDebug('aapf:images')

const fetchImageListLogic = createLogic<RootState, LogicDependency, string, PayloadAction>({
  type: fetchImageList.type,
  latest: true,

  process({ webClient, apiURL }, dispatch: Dispatch, done: () => void): void {
    debug('fetch')
    const headers = { 'Content-Type': 'application/json' }

    webClient.get<{ images: Array<Image> }>(apiURL('images'), headers).subscribe({
      next(payload: { images: Array<Image> }) {
        dispatch(fetchImageListSucceeded(payload))
        done()
      },
      error(error: ErrorType) {
        dispatch(fetchImageListFailed(error))
        done()
      }
    })
  }
})

export default fetchImageListLogic
