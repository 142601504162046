import createDebug from 'debug'
import * as R from 'ramda'
import { Observable } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import type { Debugger } from 'debug'
import type { Subscriber } from 'rxjs'
import type { AjaxResponse } from 'rxjs/ajax'

type ReqArgs =
  | [string, unknown | null, Record<string, string> | null]
  | [string, Record<string, string> | null]

class WebClient {
  ajax: typeof ajax

  debugToken: Debugger

  constructor() {
    this.ajax = ajax
    this.debugToken = createDebug('aapf:token')
  }

  /**
   * Returns prefixed API URL.
   *
   * @param  {string} path - API URL path.
   * @returns {string} Prefixed API URL.
   */
  static apiURL(path: string): string {
    return `/api/v1/${path}`
  }

  /**
   * Sends XMLHTTPRequest.
   *
   * @param  {(...rest:Array<any>)=>Observable} method - Function that sends
   * XMLHTTPRequest.
   * @param  {Array<any>} args - method's arguments.
   * Each argument types are: [url: string, body: unknown, headers: ?Record<string, string>]
   * @param  {boolean} auth - If true,
   *   - appends `Authorization: Bearer <token>` header
   *   - if the request fails with 401, tries to refresh the access token
   *     and send the last request again
   * @param  {boolean} retried - If true, not retries request after attempting
   * refresh the access token by using the refresh token stored in localStorage.
   * when the request is failed.
   * @returns {Observable} Emits the result of XMLHTTPRequest.
   */
  request<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    method: (...rest: Array<any>) => Observable<AjaxResponse>,
    args: ReqArgs,
    auth = true,
    retried = false
  ): Observable<T> {
    return new Observable((observer: Subscriber<T>) => {
      const headers: Record<string, string> = (args.slice(-1)[0] as Record<string, string>) || {}

      // For debugging
      const xhrHeaders = localStorage.getItem('xhrHeaders')
      if (xhrHeaders) {
        R.forEachObjIndexed((value: unknown, key: string | number | symbol) => {
          headers[key as string] = value as string
        }, JSON.parse(xhrHeaders))
      }

      if (auth) {
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`
        }
      }

      // replace `headers` with the updated one
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const options: Array<unknown> = [...args.slice(0, -1), headers]

      const req = method(...options)
      req.subscribe(
        (result: AjaxResponse) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          observer.next(result.response)
        },
        (error: AjaxError) => {
          if (error.status === 401 && auth && !retried && localStorage.getItem('refreshToken')) {
            this.tokenRefresh().subscribe(
              () => {
                this.debugToken('token refreshed')
                this.request<T>(method, args, auth, true).subscribe(
                  (response: T) => {
                    observer.next(response)
                  },
                  (retryError: AjaxError) => {
                    observer.error(retryError)
                  },
                  () => {
                    observer.complete()
                  }
                )
              },
              (refreshError: AjaxError) => {
                this.debugToken('token refresh failed')
                observer.error(R.assoc('tokenRefreshError', true, refreshError))
              }
            )
          } else {
            observer.error(error)
          }
        },
        () => {
          observer.complete()
        }
      )
    })
  }

  /**
   * Refreshes the access token with the refresh token stored in localStorage.
   * If refresh succeeded, store the access token to localStorage.
   *
   * @returns {Observable} Emits the result of XMLHTTPRequest.
   */
  tokenRefresh(): Observable<Record<string, unknown>> {
    this.debugToken('refreshing token')
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      return new Observable((observer: Subscriber<Record<string, unknown>>) => {
        const body = { refreshToken }
        const headers = { 'Content-Type': 'application/json' }
        this.post<Record<string, unknown>>(
          WebClient.apiURL('access-token-refresh'),
          body,
          headers,
          false
        ).subscribe(
          (response: Record<string, unknown>) => {
            localStorage.setItem('accessToken', response.accessToken as string)
            observer.next(response)
          },
          (error: AjaxError) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.debugToken('refreshing token error', error.xhr.response.message)
            observer.error(error)
          },
          () => {
            observer.complete()
          }
        )
      })
    }
    return new Observable((observer: Subscriber<Record<string, unknown>>) =>
      observer.error(new Error('there is no refresh token'))
    )
  }

  /**
   * Sends GET request.
   *
   * @param  {string} url - Endpoint of request.
   * @param  {?Record<string, string>} headers - XMLHTTPRequest headers.
   * @param  {?boolean} auth - If true, sends request with authentication
   * by using the access token in localStorage.
   * @returns {Observable} Emits the result of XMLHTTPRequest.
   */
  get<T>(url: string, headers: Record<string, string> | null, auth = true): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return this.request<T>(this.ajax.get, [url, headers], auth)
  }

  /**
   * Sends POST request.
   *
   * @param  {string} url - Endpoint of request.
   * @param  {?Object} body - XMLHTTPRequest body.
   * @param  {?Record<string, string>} headers - XMLHTTPRequest headers.
   * @param  {?boolean} auth - If true, sends request with authentication
   * by using the access token in localStorage.
   * @returns {Observable} Emits the result of XMLHTTPRequest.
   */
  post<T>(
    url: string,
    body: unknown | null,
    headers: Record<string, string> | null,
    auth = true
  ): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return this.request<T>(this.ajax.post, [url, body, headers], auth)
  }

  /**
   * Sends DELETE request.
   *
   * @param  {string} url - Endpoint of request.
   * @param  {?Record<string, string>} headers - XMLHTTPRequest headers.
   * @param  {?boolean} auth - If true, sends request with authentication
   * by using the access token in localStorage.
   * @returns {Observable} Emits the result of XMLHTTPRequest.
   */
  delete<T>(url: string, headers: Record<string, string> | null, auth = true): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return this.request<T>(this.ajax.delete, [url, headers], auth)
  }

  /**
   * Sends PUT request.
   *
   * @param  {string} url - Endpoint of request.
   * @param  {?Object} body - XMLHTTPRequest body.
   * @param  {?Record<string, string>} headers - XMLHTTPRequest headers.
   * @param  {?boolean} auth - If true, sends request with authentication
   * by using the access token in localStorage.
   * @returns {Observable} Emits the result of XMLHTTPRequest.
   */
  put<T>(
    url: string,
    body: unknown | null,
    headers: Record<string, string> | null,
    auth = true
  ): Observable<T> {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    return this.request<T>(this.ajax.put, [url, body, headers], auth)
  }
}

export default WebClient
