import React, { MouseEvent, SyntheticEvent } from 'react'
import * as R from 'ramda'
import {
  Container,
  Divider,
  DropdownProps,
  Form,
  Header,
  Icon,
  InputOnChangeData,
  Message,
  Segment,
  Select
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ClusterImageDescription from './ClusterImageDescription'
import ClusterPerformance from './ClusterPerformance'
import type { PerformanceType } from '../../ducks/perf_type'
import type { Image } from '../../ducks/image'

import './ClusterCreatePage.css'
import { ErrorType } from '../../types'
import { CreateClusterParams } from '../../ducks/cluster_creation'

export type FieldType = 'clusterName' | 'jupyterPerfTypeName' | 'jupyterImageName'

export type ClusterInfo = {
  clusterName: string
  jupyterImageName: string | null
  jupyterPerfTypeName: string | null
}

type Props = {
  clusterInfo: ClusterInfo
  isFetchingData: boolean
  fetchDataError?: string
  isValidating: boolean
  createClusterError: ErrorType | null
  create: (params: CreateClusterParams) => void
  editClusterInfo: (field: string, value: string) => void
  perfTypes: Array<PerformanceType>
  images: Array<Image>
}

const ClusterCreatePage = ({
  clusterInfo,
  isFetchingData,
  fetchDataError,
  isValidating,
  createClusterError,
  create,
  editClusterInfo,
  perfTypes,
  images
}: Props): JSX.Element => {
  const clusterName = R.propOr<string, ClusterInfo, string>('', 'clusterName', clusterInfo)
  const jupyterImageName = R.propOr<null, ClusterInfo, string | null>(
    null,
    'jupyterImageName',
    clusterInfo
  )
  const jupyterPerfTypeName = R.propOr<null, ClusterInfo, string | null>(
    null,
    'jupyterPerfTypeName',
    clusterInfo
  )
  const jupyterPerfType = jupyterPerfTypeName
    ? R.find(R.propEq('name', jupyterPerfTypeName), perfTypes)
    : undefined
  const jupyterImage = jupyterImageName
    ? R.find(R.propEq('repositoryName', jupyterImageName), images)
    : undefined

  const handleCreateCluster = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    create({
      clusterName,
      jupyterNotebook: {
        image: { repositoryName: jupyterImageName ?? '' },
        perfType: jupyterPerfTypeName ?? ''
      }
    })
  }

  const handleEditClusterInfo = (
    event: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps | InputOnChangeData,
    field: FieldType
  ) => {
    editClusterInfo(field, data.value as string)
  }

  const hasValidationError = (parameter: string) => {
    if (createClusterError && createClusterError.validationErrors) {
      const specificErrors = R.filter(
        R.propEq('parameter', parameter),
        createClusterError.validationErrors
      )
      return !R.isEmpty(specificErrors)
    }
    return false
  }

  const renderError = () => {
    const formatError = (validationError: { parameter: string; message: string }) => {
      if (validationError.parameter === 'nameOfAACluster') {
        return `Cluster name: ${validationError.message}`
      }
      if (validationError.parameter === 'jupyterNotebook.image') {
        return `Jupyter Notebook Image: ${validationError.message}`
      }
      if (validationError.parameter === '*.perfType') {
        return `${validationError.message}`
      }
      return null
    }
    if (createClusterError && createClusterError.validationErrors) {
      return (
        <Message
          id="cluster-create-modal-error-message"
          error
          header="Error"
          list={R.map(formatError)(createClusterError.validationErrors)}
        />
      )
    }
    if (createClusterError && createClusterError.message) {
      return (
        <Message
          id="cluster-create-modal-error-message"
          error
          header="Error"
          content={createClusterError.message}
        />
      )
    }
    return null
  }

  const imageOptions = R.map(
    (image) => ({
      key: image.repositoryName,
      text: image.displayName,
      value: image.repositoryName
    }),
    images
  )

  const performanceOptions = R.map(
    (perfType) => ({
      key: perfType.name,
      text: perfType.displayName,
      value: perfType.name
    }),
    perfTypes
  )

  const renderService = (
    serviceName: string,
    serviceDisplayName: string,
    image: Image | undefined,
    perfType: PerformanceType | undefined,
    fieldImage: FieldType,
    fieldPerfType: FieldType,
    id: string
  ) => {
    const undefPerfType = {
      name: '',
      displayName: '',
      resources: {
        cpu: null,
        memory: null,
        'nvidia.com/gpu': null
      }
    }
    const undefImage = {
      repositoryName: '',
      displayName: '',
      description: ''
    }
    const pt = perfType || undefPerfType
    const imgInfo = image || undefImage
    return (
      <Segment>
        <Header>{serviceDisplayName}</Header>
        <Form.Field inline>
          <label htmlFor={`${id}-image`}>Image:</label>
          <Select
            id={`${id}-image`}
            options={imageOptions}
            onChange={(e, d) => handleEditClusterInfo(e, d, fieldImage)}
            value={imgInfo.repositoryName}
            error={hasValidationError(`${serviceName}.image`)}
            disabled={isValidating || !!fetchDataError}
          />
        </Form.Field>
        <ClusterImageDescription description={imgInfo.description} />
        <Divider hidden />
        <Form.Field inline>
          <label htmlFor={`${id}-perf-type`}>Performance Type:</label>
          <Select
            id={`${id}-perf-type`}
            options={performanceOptions}
            onChange={(e, d) => handleEditClusterInfo(e, d, fieldPerfType)}
            value={pt.name}
            error={hasValidationError('*.perfType')}
            disabled={isValidating || !!fetchDataError}
          />
        </Form.Field>
        <ClusterPerformance resources={pt.resources} />
      </Segment>
    )
  }

  const renderForm = () => (
    <Form>
      <Form.Input
        id="cluster-name"
        name="cluster-name"
        label="Input Cluster Name:"
        inline
        value={clusterName}
        disabled={isValidating || !!fetchDataError}
        autoFocus
        error={hasValidationError('nameOfAACluster')}
        onChange={(e, d) => handleEditClusterInfo(e, d, 'clusterName')}
      />
      {renderService(
        'jupyterNotebook',
        'Jupyter Notebook',
        jupyterImage,
        jupyterPerfType,
        'jupyterImageName',
        'jupyterPerfTypeName',
        'jupyter'
      )}
      <Form.Button
        id="create-cluster-button"
        icon="plus"
        content="Create AACluster"
        primary
        floated="right"
        disabled={isValidating || !!fetchDataError || !clusterName || !jupyterImageName}
        onClick={handleCreateCluster}
      />
      <Link to="/" id="cancel-link">
        Cancel
      </Link>
    </Form>
  )

  return (
    <Container>
      <Link to="/">&lt; Back to Top</Link>
      {renderError()}
      <Header as="h1" key="page-title">
        Create AACluster
      </Header>
      {isFetchingData && (
        <Message>
          <Message.Content>
            <Icon loading name="spinner" />
            Preparing to create...
          </Message.Content>
        </Message>
      )}
      {fetchDataError && <Message negative header="Error" content={fetchDataError} />}
      {!isFetchingData && renderForm()}
      {isValidating && (
        <Message>
          <Message.Content>
            <Icon loading name="spinner" />
            Preparing to create...
          </Message.Content>
        </Message>
      )}
    </Container>
  )
}

ClusterCreatePage.defaultProps = {
  fetchDataError: ''
}

export default ClusterCreatePage
