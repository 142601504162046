import React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { deleteCluster, hideDeleteConfirmation } from '../../ducks/cluster'
import type { ClusterState } from '../../ducks/cluster'
import ClusterDeleteModalComponent from '../../components/Cluster/ClusterDeleteModal'

type Props = {
  clusterName: string | null
  close: () => void
  delete: (clusterName: string) => void
}

const ClusterDeleteModal = ({ clusterName, close, delete: onDelete }: Props) =>
  clusterName ? (
    <ClusterDeleteModalComponent
      clusterName={clusterName}
      isOpened={!!clusterName}
      close={close}
      delete={onDelete}
    />
  ) : null

const mapStateToProps = (state: { cluster: ClusterState }) => ({
  clusterName: state.cluster.showDeleteConfirmTargetCluster
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close() {
    dispatch(hideDeleteConfirmation())
  },
  delete(clusterName: string) {
    dispatch(hideDeleteConfirmation())
    dispatch(deleteCluster(clusterName))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterDeleteModal)
