/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type ApiTokenType = {
  tokenName: string
  createdAt: string
  isRevoking?: boolean
}

export type ApiTokensInfoFetch = {
  isFetching: boolean
  apiTokens: Array<ApiTokenType>
  errorMessage: string

  revokingTokenName: string
}

export const initialState: ApiTokensInfoFetch = {
  isFetching: false,
  apiTokens: [],
  errorMessage: '',

  revokingTokenName: ''
}

export const apiTokenFetchSlice = createSlice({
  name: 'api_token_fetch',
  initialState,
  reducers: {
    init: (state: ApiTokensInfoFetch) => initialState,
    fetchApiTokenList: (state: ApiTokensInfoFetch) => {
      state.isFetching = true
      state.apiTokens = []
      state.errorMessage = ''
    },
    fetchApiTokenListSucceed: (
      state: ApiTokensInfoFetch,
      action: PayloadAction<Array<ApiTokenType>>
    ) => {
      state.isFetching = false
      state.apiTokens = action.payload
    },
    fetchApiTokenListFailed: (state: ApiTokensInfoFetch, action: PayloadAction<ErrorType>) => {
      state.isFetching = false
      state.errorMessage = action.payload.message
    },
    revokeApiToken: (state: ApiTokensInfoFetch, action: PayloadAction<string>) => {
      state.revokingTokenName = action.payload
      state.apiTokens = R.map(
        (token: ApiTokenType) =>
          token.tokenName === action.payload ? R.mergeRight(token, { isRevoking: true }) : token,
        state.apiTokens
      )
    },
    finishRevokingApiToken: (state: ApiTokensInfoFetch) => {
      state.apiTokens = R.map(
        (token: ApiTokenType) =>
          token.tokenName === state.revokingTokenName
            ? R.mergeRight(token, { isRevoking: false })
            : token,
        state.apiTokens
      )
      state.revokingTokenName = ''
    }
  }
})

export const {
  init,
  fetchApiTokenList,
  fetchApiTokenListSucceed,
  fetchApiTokenListFailed,
  revokeApiToken,
  finishRevokingApiToken
} = apiTokenFetchSlice.actions
export default apiTokenFetchSlice.reducer
