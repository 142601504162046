/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'
import type { Image } from './image'
import type { PerformanceType } from './perf_type'

// Types

type ClusterName = string
export type InstanceStatus = 'ready' | 'notReady'

export type Instance = {
  index: number
  status: InstanceStatus
}

type JupyterNotebookInstance = Instance & {
  urlPath: string
  jupyterToken: string
}

export type Service = {
  name: string
  alerts: Array<string>
  numberOfInstances: number
  image: Image | null
  perfType: PerformanceType | null
  instances: Array<Instance>
}

export type JupyterNotebookService = Service & {
  name: 'jupyter-notebook'
  instances: Array<JupyterNotebookInstance>
}

export type ClusterStatus = 'creating' | 'created' | 'deleting'

export type Cluster = {
  name: string
  status: ClusterStatus
  createdAt: string | null
  services: Array<Service | JupyterNotebookService>
}

export type Clusters = Array<Cluster>

export type Message = {
  type: string
  message: string
}

export type ClusterState = {
  clusters: Clusters
  isFetching: boolean
  showDeleteConfirmTargetCluster: ClusterName | null
  showAlertsTargetCluster: ClusterName | null
  messages: Array<Message>
}

// Slice

export const initialState: ClusterState = {
  clusters: [],
  isFetching: false,
  showDeleteConfirmTargetCluster: null,
  showAlertsTargetCluster: null,
  messages: []
}

export const clusterSlice = createSlice({
  name: 'cluster',
  initialState,
  reducers: {
    init: (state: ClusterState) => initialState,
    pollClusterList: (state: ClusterState) => state,
    cancelPollClusterList: (state: ClusterState) => state,
    fetchCluster: (
      state: ClusterState,
      action: PayloadAction<{ clusterName: string; fetching: boolean }>
    ) => {
      state.isFetching = action.payload.fetching
    },
    fetchClusterSucceeded: (state: ClusterState, action: PayloadAction<{ aacluster: Cluster }>) => {
      state.isFetching = false
      state.clusters = [action.payload.aacluster]
    },
    fetchClusterFailed: (state: ClusterState, action: PayloadAction<ErrorType>) => {
      state.isFetching = false
      state.messages = R.uniq(
        R.append({ type: 'error', message: action.payload.message }, state.messages)
      )
    },
    fetchClusterList: (state: ClusterState, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    fetchClusterListSucceeded: (
      state: ClusterState,
      action: PayloadAction<{ aaclusters: Clusters }>
    ) => {
      state.isFetching = false
      state.clusters = action.payload.aaclusters
    },
    deleteCluster: (state: ClusterState, action: PayloadAction<string>) => state,
    deleteClusterAccepted: (state: ClusterState) => state,
    deleteClusterRejected: (state: ClusterState, action: PayloadAction<ErrorType>) => {
      state.isFetching = false
      state.messages = R.uniq(
        R.append(
          {
            type: 'error',
            message: action.payload.message
          },
          state.messages
        )
      )
    },
    dismissMessage: (state: ClusterState, action: PayloadAction<number>) => {
      state.messages = R.remove(action.payload, 1, state.messages)
    },
    showDeleteConfirmation: (state: ClusterState, action: PayloadAction<string>) => {
      state.showDeleteConfirmTargetCluster = action.payload
    },
    hideDeleteConfirmation: (state: ClusterState) => {
      state.showDeleteConfirmTargetCluster = null
    },
    showAlerts: (state: ClusterState, action: PayloadAction<string>) => {
      state.showAlertsTargetCluster = action.payload
    },
    hideAlerts: (state: ClusterState) => {
      state.showAlertsTargetCluster = null
    }
  }
})

export const {
  init,
  pollClusterList,
  cancelPollClusterList,
  fetchCluster,
  fetchClusterSucceeded,
  fetchClusterFailed,
  fetchClusterList,
  fetchClusterListSucceeded,
  deleteCluster,
  deleteClusterAccepted,
  deleteClusterRejected,
  dismissMessage,
  showDeleteConfirmation,
  hideDeleteConfirmation,
  showAlerts,
  hideAlerts
} = clusterSlice.actions
export default clusterSlice.reducer
