import * as R from 'ramda'
import React from 'react'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ClusterDetailPage from '../../components/Cluster/ClusterDetailPage'
import type { Clusters, ClusterState, ClusterStatus, InstanceStatus } from '../../ducks/cluster'
import { init as initializeAction, fetchCluster as fetchClusterAction } from '../../ducks/cluster'

type Props = {
  clusters: Clusters
  isLoading: boolean
  error?: string
  match: { params: { clusterName: string } }
  initialize: () => void
  fetchCluster: (clusterName: string) => void
}

class ClusterDetailApp extends React.Component<Props> {
  static defaultProps = {
    error: ''
  }

  componentDidMount() {
    const { match, fetchCluster } = this.props
    const clusterName = decodeURIComponent(match.params.clusterName)
    fetchCluster(clusterName)
  }

  componentWillUnmount() {
    const { initialize } = this.props
    initialize()
  }

  render() {
    const defaultPerfType = {
      name: '',
      displayName: '',
      resources: {
        cpu: null,
        memory: null,
        'nvidia.com/gpu': null
      }
    }

    const defaultImage = {
      repositoryName: '',
      displayName: '',
      description: ''
    }

    const defaultCluster = {
      name: '',
      status: 'created' as ClusterStatus,
      createdAt: null,
      services: [
        {
          name: 'jupyter-notebook',
          numberOfInstances: 1,
          instances: [
            {
              index: 0,
              status: 'ready' as InstanceStatus,
              jupyterToken: '',
              urlPath: '',
              createdAt: ''
            }
          ],
          image: defaultImage,
          perfType: defaultPerfType,
          alerts: []
        }
      ]
    }

    const { clusters, isLoading, error, match } = this.props
    const decodedClusterName = decodeURIComponent(match.params.clusterName)
    const cluster =
      R.find(R.propEq('name', decodedClusterName), clusters) ||
      R.mergeRight(defaultCluster, { name: decodedClusterName })
    return <ClusterDetailPage cluster={cluster} isLoading={isLoading} error={error} />
  }
}

const mapStateToProps = (state: { cluster: ClusterState }) => ({
  clusters: state.cluster.clusters,
  isLoading: state.cluster.isFetching,
  error: R.prop('message', state.cluster.messages[0])
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initialize() {
    dispatch(initializeAction())
  },
  fetchCluster(clusterName: string) {
    dispatch(fetchClusterAction({ clusterName, fetching: true }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterDetailApp)
