import React, { MouseEvent } from 'react'
import * as R from 'ramda'
import { Header, Menu, Container } from 'semantic-ui-react'

import './UserInfoMenu.css'

type Props = {
  userName?: string | null
  path: string
  onSelectMenu: (name: string | undefined) => void
  children?: React.ReactNode
}

class UserInfoMenu extends React.PureComponent<Props> {
  static defaultProps = {
    children: null
  }

  handleSelectMenu(
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    data: { name?: string }
  ): void {
    const { onSelectMenu } = this.props
    e.preventDefault()
    onSelectMenu(data.name)
  }

  parseSelectedMenuName(): string | undefined {
    const { path } = this.props
    const pathParts = R.split('/', path)
    return R.nth(R.indexOf('user-profile', pathParts) + 1)(pathParts)
  }

  render(): JSX.Element {
    const { userName, children } = this.props
    const selectedMenu = this.parseSelectedMenuName()

    return (
      <div>
        <Header as="h1" className="user_info_bar" dividing>
          {userName}
        </Header>
        <Container>
          <Menu className="user-info-tab" tabular>
            <Menu.Item
              id="link-api-token"
              name="api-token"
              active={selectedMenu === 'api-token'}
              onClick={(e, data) => this.handleSelectMenu(e, data)}
            >
              API Tokens
            </Menu.Item>
            <Menu.Item
              id="link-change-password"
              name="change-password"
              active={selectedMenu === 'change-password'}
              onClick={(e, data) => this.handleSelectMenu(e, data)}
            >
              Change Password
            </Menu.Item>
          </Menu>
        </Container>
        {children}
      </div>
    )
  }
}

export default UserInfoMenu
