import React, { MouseEvent } from 'react'
import { Button, Message, Modal } from 'semantic-ui-react'

type Props = {
  clusterName: string
  isOpened?: boolean
  close: () => void
  delete: (clusterName: string) => void
}

const ClusterDeleteModal = ({
  clusterName,
  isOpened,
  close,
  delete: onDelete
}: Props): JSX.Element => {
  const handleDelete = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    onDelete(clusterName)
  }

  const handleClose = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    close()
  }

  return (
    <Modal closeOnDimmerClick={false} open={isOpened} onClose={handleClose}>
      <Modal.Header>
        Delete AACluster:
        {clusterName}
      </Modal.Header>
      <Modal.Content>
        <Message warning>
          <Message.Content>
            Make sure that running Notebooks do not remain on the cluster.
            <br />
            Notebooks will be halted after the cluster is deleted.
          </Message.Content>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button id="delete-cluster" content="Delete AACluster" negative onClick={handleDelete} />
        <Button className="cancel" content="Cancel" onClick={handleClose} />
      </Modal.Actions>
    </Modal>
  )
}

ClusterDeleteModal.defaultProps = {
  isOpened: false
}

export default ClusterDeleteModal
