import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ApiTokenTableComponent from '../../components/ApiToken/ApiTokenTable'
import { openRevokeApiTokenModal } from '../../ducks/api_token_revoke'
import type { ApiTokensInfoFetch } from '../../ducks/api_token_fetch'

type ApiToken = {
  tokenName: string
  createdAt: string
  isRevoking?: boolean
}

type Props = {
  apiTokens: Array<ApiToken>
  isLoading: boolean
  openRevokeModal: (tokenName: string) => void
}

const ApiTokenTable = ({ apiTokens, isLoading, openRevokeModal }: Props) => (
  <ApiTokenTableComponent
    apiTokens={apiTokens}
    isLoading={isLoading}
    openRevokeModal={openRevokeModal}
  />
)

const mapStateToProps = (state: { apiTokenFetch: ApiTokensInfoFetch }) => ({
  apiTokens: state.apiTokenFetch.apiTokens,
  isLoading: state.apiTokenFetch.isFetching
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openRevokeModal(tokenName: string) {
    dispatch(openRevokeApiTokenModal(tokenName))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenTable)
