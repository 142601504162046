/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type CreateClusterParams = {
  clusterName: string
  jupyterNotebook: {
    image: { repositoryName: string }
    perfType: string
  }
}

export type ClusterCreationState = {
  isValidating: boolean
  createClusterInfo: {
    clusterName: string
    jupyterPerfTypeName: string | null
    jupyterImageName: string | null
  }
  error: ErrorType | null
  scrollToTop: boolean
}

export const initialState: ClusterCreationState = {
  isValidating: false,
  createClusterInfo: {
    clusterName: '',
    jupyterPerfTypeName: null,
    jupyterImageName: null
  },
  error: null,
  scrollToTop: false
}

export const clusterCreationSlice = createSlice({
  name: 'cluster_creation',
  initialState,
  reducers: {
    init: (state: ClusterCreationState) => initialState,
    editCreateClusterInfo: (
      state: ClusterCreationState,
      action: PayloadAction<{ field: string; value: string }>
    ) => {
      if (action.payload.field === 'clusterName') {
        state.createClusterInfo.clusterName = action.payload.value
      } else if (action.payload.field === 'jupyterPerfTypeName') {
        state.createClusterInfo.jupyterPerfTypeName = action.payload.value
      } else if (action.payload.field === 'jupyterImageName') {
        state.createClusterInfo.jupyterImageName = action.payload.value
      }
      state.scrollToTop = false
    },
    createCluster: (state: ClusterCreationState, action: PayloadAction<CreateClusterParams>) => {
      state.isValidating = true
    },
    createClusterAccepted: (state: ClusterCreationState) => {
      state.isValidating = false
    },
    createClusterRejected: (state: ClusterCreationState, action: PayloadAction<ErrorType>) => {
      state.isValidating = false
      state.scrollToTop = true
      state.error = action.payload
    }
  }
})

export const {
  init,
  editCreateClusterInfo,
  createCluster,
  createClusterAccepted,
  createClusterRejected
} = clusterCreationSlice.actions
export default clusterCreationSlice.reducer
