import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { push, RouterState } from 'connected-react-router'
import type { Dispatch } from 'redux'
import UserInfoMenu from '../../components/UserInfo/UserInfoMenu'
import ApiTokenApp from '../ApiToken/ApiTokenApp'
import ChangePasswordApp from '../ChangePassword/ChangePasswordApp'
import type { AuthState } from '../../ducks/auth'

type Props = {
  path: string
  userName: string | null
  onSelectMenu: (name: string | undefined) => void
}

class UserInfoApp extends React.PureComponent<Props> {
  render() {
    const { userName, path, onSelectMenu } = this.props
    return (
      <UserInfoMenu userName={userName} path={path} onSelectMenu={onSelectMenu}>
        <Switch>
          <Route path="/user-profile/api-token" component={ApiTokenApp} />
          <Route path="/user-profile/change-password" component={ChangePasswordApp} />
        </Switch>
      </UserInfoMenu>
    )
  }
}

const mapStateToProps = (state: { auth: AuthState; router: RouterState }) => ({
  userName: state.auth.username,
  path: state.router.location.pathname
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSelectMenu(name: string | undefined) {
    dispatch(push(`/user-profile/${name ?? ''}`))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoApp)
