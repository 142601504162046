import * as React from 'react'
import { Route, Switch } from 'react-router'
import Header from './Header/Header'
import ClusterApp from './Cluster/ClusterApp'
import ClusterCreateApp from './Cluster/ClusterCreateApp'
import ClusterDetailApp from './Cluster/ClusterDetailApp'
import LoginApp from './Login/LoginApp'
import UserInfoApp from './UserInfo/UserInfoApp'
import { withAuth, withoutAuth } from './auth_helper'
import './App.css'

const App = (): JSX.Element => (
  <div id="app" className="app">
    <Header />
    <Switch>
      <Route exact path="/" component={withAuth(ClusterApp)} />
      <Route exact path="/cluster-create" component={withAuth(ClusterCreateApp)} />
      <Route exact path="/login" component={withoutAuth(LoginApp)} />
      <Route path="/user-profile" component={withAuth(UserInfoApp)} />
      <Route path="/cluster-detail/:clusterName" component={withAuth(ClusterDetailApp)} />
    </Switch>
  </div>
)

export default App
