import 'semantic-ui-css/semantic.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import App from './containers/App'
import configureStore from './redux/store'
import { loginResume } from './ducks/auth'
import './index.css'

const history = createBrowserHistory()
const store = configureStore(history)

const accessToken = localStorage.getItem('accessToken')
if (accessToken) {
  store.dispatch(loginResume())
}

const root = document.getElementById('root')

if (root) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    root
  )
}
