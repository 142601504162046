import React, { MouseEvent } from 'react'
import { Modal, Button, Message } from 'semantic-ui-react'

type Props = {
  isOpen: boolean
  tokenName: string
  revoke: (tokenName: string) => void
  close: () => void
}
const ApiTokenRevokeModal = ({ isOpen, tokenName, revoke, close }: Props): JSX.Element => {
  const handleClose = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    close()
  }

  const handleRevoke = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    revoke(tokenName)
  }

  return (
    <Modal closeOnDimmerClick={false} open={isOpen} onClose={handleClose}>
      <Modal.Header>Revoke API Token</Modal.Header>
      <Modal.Content>
        <Message warning>
          Are you sure to revoke &quot;
          {tokenName}
          &quot; API Token?
          <br />
          This action cannot be undone.
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button id="revoke-api-token" content="Revoke" negative onClick={handleRevoke} />
        <Button id="close-api-token-revoke-modal" content="Cancel" onClick={handleClose} />
      </Modal.Actions>
    </Modal>
  )
}

export default ApiTokenRevokeModal
