/* eslint-disable @typescript-eslint/no-explicit-any */
import createDebug from 'debug'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { createLogic } from 'redux-logic'
import type { Dispatch } from 'redux'
import type { PayloadAction } from '@reduxjs/toolkit'
import errorObject from './helper'
import {
  fetchApiTokenList,
  fetchApiTokenListSucceed,
  fetchApiTokenListFailed,
  finishRevokingApiToken
} from '../ducks/api_token_fetch'
import {
  createApiToken,
  createApiTokenSucceed,
  createApiTokenFailed
} from '../ducks/api_token_create'
import {
  revokeApiToken,
  revokeApiTokenSucceed,
  revokeApiTokenFailed
} from '../ducks/api_token_revoke'
import type { ApiTokenType } from '../ducks/api_token_fetch'
import type { ErrorPayload, ErrorType } from '../types'
import type { LogicDependency, RootState } from '../redux/store'

type ApiTokenWithTokenStr = ApiTokenType & {
  token: string
}

const debug = createDebug('aapf:api_token')

export const fetchApiTokenListLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction
>({
  type: fetchApiTokenList.type,
  latest: true,

  processOptions: {
    dispatchReturn: true,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    successType: (result: any) => fetchApiTokenListSucceed(result.apiTokens),
    failType: (error: Error | undefined) => fetchApiTokenListFailed(error as ErrorType)
  },

  process({ webClient, apiURL }): Observable<{ apiTokens: Array<ApiTokenType> }> {
    debug('fetch')
    const headers = { 'Content-Type': 'application/json' }

    return webClient.get<{ apiTokens: Array<ApiTokenType> }>(apiURL('api-tokens'), headers).pipe(
      catchError((error: ErrorPayload) => {
        throw errorObject(error)
      })
    )
  }
})

export const createApiTokenLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<{ tokenName: string; password: string }>
>({
  type: createApiToken.type,
  latest: true,

  process({ action, webClient, apiURL }, dispatch: Dispatch, done: () => void): void {
    debug('create')
    const { tokenName, password } = action.payload
    const headers = { 'Content-Type': 'application/json' }
    const body = { password }
    const encodedTokenName = encodeURIComponent(tokenName)

    webClient
      .put<{ apiToken: ApiTokenWithTokenStr }>(
        apiURL(`api-tokens/${encodedTokenName}`),
        body,
        headers
      )
      .subscribe({
        next({ apiToken }) {
          const createdTokenInfo = {
            tokenName: apiToken.tokenName,
            createdAt: apiToken.createdAt,
            token: apiToken.token
          }
          dispatch(createApiTokenSucceed(createdTokenInfo))
          dispatch(fetchApiTokenList())
          done()
        },
        error(error: ErrorPayload) {
          dispatch(createApiTokenFailed(errorObject(error)))
          done()
        }
      })
  }
})

export const revokeApiTokenLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<string>
>({
  type: revokeApiToken.type,
  latest: true,

  process({ action, webClient, apiURL }, dispatch: Dispatch, done: () => void): void {
    debug('revoke')
    const revokingTokenName = action.payload
    const headers = { 'Content-Type': 'application/json' }
    const encodedTokenName = encodeURIComponent(revokingTokenName)

    webClient.delete(apiURL(`api-tokens/${encodedTokenName}`), headers).subscribe({
      next() {
        dispatch(revokeApiTokenSucceed())
        dispatch(fetchApiTokenList())
        done()
      },
      error(error: ErrorPayload) {
        dispatch(revokeApiTokenFailed(errorObject(error)))
        dispatch(finishRevokingApiToken())
        done()
      }
    })
  }
})
