import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ApiTokenMenuBarComponent from '../../components/ApiToken/ApiTokenMenuBar'
import { fetchApiTokenList } from '../../ducks/api_token_fetch'
import { openCreateApiTokenModal as openCreateApiTokenModalAction } from '../../ducks/api_token_create'
import type { ApiTokensInfoFetch } from '../../ducks/api_token_fetch'

type Props = {
  isLoading: boolean
  refreshApiToken: () => void
  openCreateApiTokenModal: () => void
}

const ApiTokenMenuBar = ({ isLoading, refreshApiToken, openCreateApiTokenModal }: Props) => (
  <ApiTokenMenuBarComponent
    isLoading={isLoading}
    refreshTokenList={refreshApiToken}
    openCreateModal={openCreateApiTokenModal}
  />
)

const mapStateToProps = (state: { apiTokenFetch: ApiTokensInfoFetch }) => ({
  isLoading: state.apiTokenFetch.isFetching
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  refreshApiToken() {
    dispatch(fetchApiTokenList())
  },
  openCreateApiTokenModal() {
    dispatch(openCreateApiTokenModalAction())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenMenuBar)
