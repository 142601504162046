import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ClusterAlertModalComponent from '../../components/Cluster/ClusterAlertModal'
import { hideAlerts } from '../../ducks/cluster'
import type { Clusters, ClusterState } from '../../ducks/cluster'

type Props = {
  clusterName: string | null
  clusters: Clusters
  close: () => void
}
const ClusterAlertModal = ({ clusterName, clusters, close }: Props) => {
  const collectAlerts = () => {
    const cluster = R.find(R.propEq('name', clusterName), clusters)
    if (!cluster) return {}
    const jupyterNotebook = R.find(R.propEq('name', 'jupyter-notebook'), cluster.services)
    return {
      jupyterNotebook: jupyterNotebook?.alerts
    }
  }
  return clusterName ? (
    <ClusterAlertModalComponent
      clusterName={clusterName}
      alerts={collectAlerts()}
      isOpened={!!clusterName}
      close={close}
    />
  ) : null
}

const mapStateToProps = (state: { cluster: ClusterState }) => ({
  clusterName: state.cluster.showAlertsTargetCluster,
  clusters: state.cluster.clusters
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close() {
    dispatch(hideAlerts())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterAlertModal)
