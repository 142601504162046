import * as R from 'ramda'
import React, { ChangeEvent, MouseEvent } from 'react'
import { Button, Container, Form, Message, Icon, Input } from 'semantic-ui-react'
import zxcvbn from 'zxcvbn'

import './ChangePassword.css'

type State = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
  isStartedCheck: boolean
}

type Props = {
  onSubmit: (currentPassword: string, newPassword: string) => void
  initialize: () => void
  changedWithSuccess: boolean
  waitingServerResponse: boolean
  error: string | null
  username: string
}

type PassStrength = {
  passStrengthScore: zxcvbn.ZXCVBNScore
  passStrengthText: string
  passStrengthColor: string
}

class ChangePassword extends React.Component<Props, State> {
  static defaultProps = {
    error: null
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isStartedCheck: false
    }
  }

  handleChangePassword(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    const { currentPassword, newPassword, confirmPassword } = this.state
    const { initialize, onSubmit } = this.props
    event.preventDefault()
    if (newPassword !== confirmPassword) {
      this.setState({ isStartedCheck: true })
      initialize()
    } else {
      this.setState({ isStartedCheck: false })
      onSubmit(currentPassword, newPassword)
    }
  }

  onChangeForm(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.name === 'currentPassword') {
      this.setState({ currentPassword: event.target.value })
    } else if (event.target.name === 'newPassword') {
      this.setState({ newPassword: event.target.value })
    } else if (event.target.name === 'confirmPassword') {
      this.setState({ confirmPassword: event.target.value })
    }
  }

  evalPassStrength(username: string, newPassword: string): PassStrength {
    const { score } = zxcvbn(newPassword, [username])
    switch (score) {
      case 4:
        return {
          passStrengthScore: score,
          passStrengthText: 'Strong',
          passStrengthColor: 'green'
        }
      case 3:
        return {
          passStrengthScore: score,
          passStrengthText: 'Moderate',
          passStrengthColor: 'yellow'
        }
      case 2:
        return {
          passStrengthScore: score,
          passStrengthText: 'Weak',
          passStrengthColor: 'red'
        }
      default:
        return {
          passStrengthScore: score,
          passStrengthText: 'Risky',
          passStrengthColor: 'grey'
        }
    }
  }

  renderStatus(): JSX.Element | null {
    const { waitingServerResponse, changedWithSuccess } = this.props

    if (!waitingServerResponse && !changedWithSuccess) return null

    if (changedWithSuccess) {
      return (
        <Message
          id="success-message"
          success
          header="Done"
          content="Password is changed successfully."
        />
      )
    }

    return (
      <Message>
        <Icon loading name="spinner" />
        Changing password...
      </Message>
    )
  }

  renderWaitingServerResponsePasswordError(): JSX.Element | null {
    const { error } = this.props
    if (!error) return null

    return <Message id="error-message" negative header="Changing password failed" content={error} />
  }

  renderInputPasswordsInvalidError(): JSX.Element | null {
    const { isStartedCheck, newPassword, confirmPassword } = this.state
    if (!isStartedCheck || R.isEmpty(confirmPassword) || newPassword === confirmPassword) {
      return null
    }

    return (
      <Message
        id="input-passwords-error-message"
        negative
        header="Validation error"
        content="Password confirmation doesn't match New password."
      />
    )
  }

  render(): JSX.Element {
    const { waitingServerResponse, username } = this.props
    const { currentPassword, newPassword, confirmPassword } = this.state
    const { passStrengthScore, passStrengthText, passStrengthColor } = this.evalPassStrength(
      username,
      newPassword
    )

    return (
      <Container className="ui container changepassword">
        <p>Change your password</p>
        <Form name="change-password">
          <Form.Input
            name="currentPassword"
            label="Current password"
            placeholder="Current password"
            input="password"
            onChange={(e) => this.onChangeForm(e)}
            disabled={waitingServerResponse}
          />
          <Form.Field className="newpassword">
            <label htmlFor="newPassword">New password</label>
            <Input
              name="newPassword"
              type="password"
              placeholder="New password"
              onChange={(e) => this.onChangeForm(e)}
              disabled={waitingServerResponse}
              labelPosition="right"
              label={{
                content: `Strength: ${passStrengthText}`,
                color: passStrengthColor,
                className: 'strength'
              }}
            />
          </Form.Field>
          <Form.Input
            name="confirmPassword"
            label="Password confirmation"
            placeholder="Password confirmation"
            input="password"
            onChange={(e) => this.onChangeForm(e)}
            disabled={waitingServerResponse}
          />
          <Button
            id="changepassword-button"
            content="Change password"
            onClick={(event) => this.handleChangePassword(event)}
            disabled={
              R.isEmpty(newPassword) ||
              R.isEmpty(currentPassword) ||
              R.isEmpty(confirmPassword) ||
              waitingServerResponse ||
              passStrengthScore < 2
            }
            primary
          />
        </Form>
        {this.renderInputPasswordsInvalidError()}
        {this.renderStatus()}
        {this.renderWaitingServerResponsePasswordError()}
      </Container>
    )
  }
}

export default ChangePassword
