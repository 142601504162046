import { connect } from 'react-redux'
import React from 'react'
import { push } from 'connected-react-router'
import type { Dispatch } from 'redux'
import { fetchClusterList } from '../../ducks/cluster'
import type { ClusterState } from '../../ducks/cluster'
import ClusterMenuBarComponent from '../../components/Cluster/ClusterMenuBar'

export type Props = {
  refreshing: boolean
  refresh: () => void
  createCluster: () => void
}

const ClusterMenuBar = ({ refreshing, refresh, createCluster }: Props) => (
  <ClusterMenuBarComponent
    refreshing={refreshing}
    refresh={refresh}
    createCluster={createCluster}
  />
)

const mapStateToProps = (state: { cluster: ClusterState }) => ({
  refreshing: state.cluster.isFetching
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  refresh() {
    dispatch(fetchClusterList(true))
  },
  createCluster() {
    dispatch(push('/cluster-create'))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ClusterMenuBar)
