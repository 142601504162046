import React from 'react'
import { Grid, Header } from 'semantic-ui-react'

import type { ServiceResources } from '../../ducks/perf_type'
import './ClusterPerformance.css'

type Props = {
  resources: ServiceResources
}

const ClusterPerformance = (props: Props): JSX.Element => {
  const { resources } = props

  const hasGpu: boolean = resources['nvidia.com/gpu'] !== null
  const columnCount = hasGpu ? 3 : 2 // CPU & Memory always enable

  return (
    <Grid columns={columnCount} padded divided className="cluster-performance">
      <Grid.Column>
        <Header as="h5">
          CPU:
          {`${resources.cpu ? resources.cpu : '-'} Core`}
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header as="h5">
          Memory:
          {`${resources.memory ? resources.memory : '-'} MB`}
        </Header>
      </Grid.Column>
      {hasGpu && (
        <Grid.Column>
          <Header as="h5">
            GPU:
            {`${resources['nvidia.com/gpu'] ? resources['nvidia.com/gpu'] : '-'}`}
          </Header>
        </Grid.Column>
      )}
    </Grid>
  )
}

ClusterPerformance.defaultProps = {}

export default ClusterPerformance
