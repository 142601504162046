import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'
import type { Dispatch } from 'redux'
import {
  init as initializeAction,
  pollClusterList as pollClusterListAction,
  cancelPollClusterList as cancelPollClusterListAction,
  fetchClusterList as fetchClusterListAction
} from '../../ducks/cluster'
import ClusterTable from './ClusterTable'
import ClusterMenuBar from './ClusterMenuBar'
import ClusterDeleteModal from './ClusterDeleteModal'
import ClusterMessages from './ClusterMessages'
import ClusterAlertModal from './ClusterAlertModal'

type Props = {
  initialize: () => void
  fetchClusterList: () => void
  pollClusterList: () => void
  cancelPollClusterList: () => void
}
class ClusterApp extends Component<Props> {
  componentDidMount() {
    const { fetchClusterList, pollClusterList } = this.props
    fetchClusterList()
    pollClusterList()
  }

  componentWillUnmount() {
    const { cancelPollClusterList, initialize } = this.props
    cancelPollClusterList()
    initialize()
  }

  render() {
    return (
      <Container className="main">
        <ClusterMessages />
        <ClusterMenuBar />
        <ClusterTable />
        <ClusterDeleteModal />
        <ClusterAlertModal />
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initialize() {
    dispatch(initializeAction())
  },
  fetchClusterList() {
    dispatch(fetchClusterListAction(true))
  },
  pollClusterList() {
    dispatch(pollClusterListAction())
  },
  cancelPollClusterList() {
    dispatch(cancelPollClusterListAction())
  }
})

export default connect(null, mapDispatchToProps)(ClusterApp)
