import React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ChangePassword from '../../components/ChangePassword/ChangePassword'
import { changePassword, init as initializeAction } from '../../ducks/change_password'
import type { ChangePasswordState } from '../../ducks/change_password'
import type { AuthState } from '../../ducks/auth'

type Props = {
  onSubmit: (currentPassword: string, newPassword: string) => void
  initialize: () => void
  changedWithSuccess: boolean
  waitingServerResponse: boolean
  error: string | null
  username: string | null
}

class ChangePasswordApp extends React.PureComponent<Props> {
  static defaultProps = {
    error: null
  }

  componentWillUnmount() {
    const { initialize } = this.props
    initialize()
  }

  render() {
    const { onSubmit, initialize, changedWithSuccess, waitingServerResponse, error, username } =
      this.props
    return (
      <ChangePassword
        waitingServerResponse={waitingServerResponse}
        initialize={initialize}
        error={error}
        onSubmit={onSubmit}
        changedWithSuccess={changedWithSuccess}
        username={username ?? ''}
      />
    )
  }
}

const mapStateToProps = (state: { auth: AuthState; changePassword: ChangePasswordState }) => ({
  changedWithSuccess: state.changePassword.changedWithSuccess,
  waitingServerResponse: state.changePassword.waitingServerResponse,
  error: state.changePassword.error,
  username: state.auth.username
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initialize() {
    dispatch(initializeAction())
  },
  onSubmit(currentPassword: string, newPassword: string) {
    dispatch(changePassword({ currentPassword, newPassword }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordApp)
