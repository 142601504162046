/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type Image = {
  repositoryName: string
  displayName: string
  description: string
}

export type ImageState = {
  isFetching: boolean
  error: string
  images: Array<Image>
}

export const initialState: ImageState = {
  isFetching: false,
  error: '',
  images: []
}

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    init: (state: ImageState) => initialState,
    fetchImageList: (state: ImageState) => {
      state.isFetching = true
    },
    fetchImageListSucceeded: (
      state: ImageState,
      action: PayloadAction<{ images: Array<Image> }>
    ) => {
      state.isFetching = false
      state.images = action.payload.images
    },
    fetchImageListFailed: (state: ImageState, action: PayloadAction<ErrorType>) => {
      state.isFetching = false
      state.error = action.payload.message
    }
  }
})

export const { init, fetchImageList, fetchImageListSucceeded, fetchImageListFailed } =
  imageSlice.actions
export default imageSlice.reducer
