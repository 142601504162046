import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ApiTokenCreateResultModalComponent from '../../components/ApiToken/ApiTokenCreateResultModal'
import { closeCreateResultModal } from '../../ducks/api_token_create'
import type { ApiTokensInfoCreate } from '../../ducks/api_token_create'

type Props = {
  isOpen: boolean
  createdToken: string
  createdTokenName: string
  close: () => void
}

const ApiTokenCreateResultModal = ({ isOpen, createdToken, createdTokenName, close }: Props) => (
  <ApiTokenCreateResultModalComponent
    isOpen={isOpen}
    createdToken={createdToken}
    createdTokenName={createdTokenName}
    close={close}
  />
)

const mapStateToProps = (state: { apiTokenCreate: ApiTokensInfoCreate }) => ({
  isOpen: state.apiTokenCreate.isOpenCreateResultModal,
  createdToken: state.apiTokenCreate.createdTokenInfo.token,
  createdTokenName: state.apiTokenCreate.createdTokenInfo.tokenName
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  close() {
    dispatch(closeCreateResultModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenCreateResultModal)
