/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type AuthState = {
  authenticating: boolean
  username: string | null
  error: string | null
}

export const initialState: AuthState = {
  authenticating: false,
  username: null,
  error: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<{ username: string; password: string }>) => {
      state.authenticating = true
      state.error = null
    },
    loginSucceeded: (state: AuthState, action: PayloadAction<string>) => {
      state.authenticating = false
      state.username = action.payload
      state.error = null
    },
    loginFailed: (state: AuthState, action: PayloadAction<ErrorType>) => {
      state.authenticating = false
      state.error = action.payload.message
    },
    loginResume: (state: AuthState) => state,
    logout: (state: AuthState, action: PayloadAction<boolean>) => {
      state.username = null
      state.error = null
    }
  }
})

export const { login, loginSucceeded, loginFailed, loginResume, logout } = authSlice.actions
export default authSlice.reducer
