import createDebug from 'debug'
import jwtDecode from 'jwt-decode'
import { Observable, empty } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import * as R from 'ramda'
import { createLogic } from 'redux-logic'
import type { Dispatch } from 'redux'
import type { PayloadAction } from '@reduxjs/toolkit'
import errorObject from './helper'
import { login, loginResume, loginSucceeded, loginFailed, logout } from '../ducks/auth'
import type { LogicDependency, RootState } from '../redux/store'
import type { ErrorPayload, ErrorType } from '../types'

const debug = createDebug('aapf:auth')

export const loginLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction<{ username: string; password: string }>
>({
  type: login.type,

  processOptions: {
    dispatchReturn: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    successType: (username: any) => loginSucceeded(username),
    failType: (error: Error | undefined) => loginFailed(error as ErrorType)
  },

  process({ action, webClient, apiURL }): Observable<string> {
    debug('login')
    const { username, password } = action.payload
    const body = { username, password }
    const headers = { 'Content-Type': 'application/json' }
    return webClient
      .post<{ accessToken: string; refreshToken: string }>(apiURL('login'), body, headers, false)
      .pipe(
        map(({ accessToken, refreshToken }) => {
          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('refreshToken', refreshToken)
          return username
        }),
        catchError((error: ErrorPayload) => {
          throw errorObject(error)
        })
      )
  }
})

export const loginResumeLogic = createLogic<RootState, LogicDependency, string, PayloadAction>({
  type: loginResume.type,

  processOptions: {
    dispatchReturn: true
  },

  process() {
    debug('login resume')
    const accessToken = localStorage.getItem('accessToken') ?? ''
    const tokenData = jwtDecode(accessToken)
    const { user } = tokenData as { user: { username: string } }
    const { username } = user
    return loginSucceeded(username)
  }
})

export const logoutLogic = createLogic<RootState, LogicDependency, string, PayloadAction>({
  type: logout.type,

  process({ action, webClient, apiURL }, dispatch: Dispatch, done: () => void): void {
    debug('logout')
    if (R.path(['payload', 'requestLogout'], action)) {
      const headers = { 'Content-Type': 'application/json' }
      webClient
        .post(apiURL('logout'), null, headers)
        .pipe(catchError(() => empty())) // ignore error
        .subscribe()
    }
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    done()
  }
})

export const autoLogoutLogic = createLogic({
  type: new RegExp('(Rejected|Failed)$'),

  process({ action }, dispatch: Dispatch, done: () => void) {
    if (
      action.type !== loginFailed.type &&
      R.path(['payload', 'status'], action) === 401 &&
      R.path(['payload', 'tokenRefreshError'], action) === true
    ) {
      debug('auto logout')
      dispatch(logout(false))
    }
    done()
  }
})
