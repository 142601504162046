import React, { MouseEvent } from 'react'
import { Menu, Button } from 'semantic-ui-react'
import './ApiTokenMenuBar.css'

type Props = {
  isLoading: boolean
  refreshTokenList: () => void
  openCreateModal: () => void
}

const ApiTokenMenuBar = ({ isLoading, refreshTokenList, openCreateModal }: Props): JSX.Element => {
  const handleOpenCreateModal = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    openCreateModal()
  }

  const handleRefreshTokenList = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    refreshTokenList()
  }

  return (
    <div>
      <Menu className="menu-bar" secondary size="mini">
        <Menu.Menu position="right">
          <Button
            id="open-api-token-create-modal"
            icon="plus"
            content="Create Token"
            primary
            size="small"
            onClick={handleOpenCreateModal}
          />
          <Button
            id="refresh-api-tokens"
            icon="refresh"
            content="Refresh"
            basic
            color="black"
            size="small"
            onClick={handleRefreshTokenList}
            disabled={isLoading}
          />
        </Menu.Menu>
      </Menu>
    </div>
  )
}

export default ApiTokenMenuBar
