import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import type { Dispatch } from 'redux'
import Header from '../../components/Header/Header'
import { logout } from '../../ducks/auth'
import type { AuthState } from '../../ducks/auth'

const mapStateToProps = (state: { auth: AuthState }) => ({
  username: state.auth.username
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout() {
    dispatch(logout(true))
    dispatch(push('/login'))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
