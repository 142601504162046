import React, { FormEvent, MouseEvent } from 'react'
import { Button, Container, Form, Icon, Message } from 'semantic-ui-react'

import './Login.css'

type Props = {
  authenticating: boolean
  error: string | null
  onLogin: (username: string, password: string) => void
}

class Login extends React.PureComponent<Props> {
  usernameField?: HTMLInputElement

  passwordField?: HTMLInputElement

  static defaultProps = {
    error: null
  }

  handleLogin(
    event: MouseEvent<HTMLElement, globalThis.MouseEvent> | FormEvent<HTMLFormElement>
  ): void {
    const { onLogin } = this.props
    event.preventDefault()
    if (this.usernameField && this.passwordField) {
      onLogin(this.usernameField.value, this.passwordField.value)
    }
  }

  focus(): void {
    if (this.usernameField) {
      this.usernameField.focus()
    }
  }

  selectPassword(): void {
    if (this.passwordField) {
      this.passwordField.select()
    }
  }

  render(): JSX.Element {
    const { authenticating, error } = this.props

    return (
      <Container text className="main main-content Login">
        <h1 id="login-title">Login to AAPF</h1>
        <Form name="login-form" onSubmit={(e) => this.handleLogin(e)}>
          <Form.Field>
            <label htmlFor="username">
              Username
              <input
                name="username"
                type="text"
                disabled={authenticating}
                ref={(elem) => {
                  this.usernameField = elem ?? undefined
                }}
              />
            </label>
          </Form.Field>
          <Form.Field>
            <label htmlFor="password">
              Password
              <input
                name="password"
                type="password"
                disabled={authenticating}
                ref={(elem) => {
                  this.passwordField = elem ?? undefined
                }}
              />
            </label>
          </Form.Field>
          <Form.Field id="login-button-field">
            <Button
              id="login-button"
              content="Login"
              positive
              disabled={authenticating}
              onClick={(e) => this.handleLogin(e)}
            />
          </Form.Field>
        </Form>
        <Message hidden={!authenticating}>
          <Icon loading name="spinner" />
          Logging in...
        </Message>
        <Message
          id="login-error-message"
          negative
          header="Login failed"
          content={error}
          hidden={!error}
        />
      </Container>
    )
  }
}

export default Login
