import * as R from 'ramda'
import React, { MouseEvent } from 'react'
import { Table, Button, Icon } from 'semantic-ui-react'

import '../../containers/App.css'

type ApiToken = {
  tokenName: string
  createdAt: string
  isRevoking?: boolean
}

type Props = {
  apiTokens: Array<ApiToken>
  isLoading: boolean
  openRevokeModal: (tokenName: string) => void
}

const ApiTokenTable = ({ apiTokens, isLoading, openRevokeModal }: Props): JSX.Element => {
  const renderLoading = () => (
    <Table.Row id="loading-row">
      <Table.Cell colSpan="3" className="message">
        <Icon loading name="spinner" />
        Loading...
      </Table.Cell>
    </Table.Row>
  )

  const renderNoToken = () => (
    <Table.Row id="no-token-row">
      <Table.Cell colSpan="3" className="message">
        No tokens found.
      </Table.Cell>
    </Table.Row>
  )

  const handleRemoveToken = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    tokenName: string
  ) => {
    e.preventDefault()
    openRevokeModal(tokenName)
  }

  const renderTokenRow = (apiToken: ApiToken) => (
    <Table.Row
      key={`apiToken-row-${encodeURIComponent(apiToken.tokenName)}`}
      id={`api-token-row-${encodeURIComponent(apiToken.tokenName)}`}
      className="token"
    >
      <Table.Cell
        className="name"
        id={`api-token-name-${encodeURIComponent(apiToken.tokenName)}-cell`}
      >
        {apiToken.tokenName}
      </Table.Cell>
      <Table.Cell className="created-at">
        {apiToken.createdAt && new Date(apiToken.createdAt).toLocaleString()}
      </Table.Cell>
      <Table.Cell className="actions">
        <Button
          id={`open-revoke-${encodeURIComponent(apiToken.tokenName)}-token-modal`}
          className="revoke"
          content="Revoke"
          icon="trash"
          size="mini"
          compact
          loading={apiToken.isRevoking}
          disabled={apiToken.isRevoking}
          onClick={(e) => handleRemoveToken(e, apiToken.tokenName)}
        />
      </Table.Cell>
    </Table.Row>
  )

  const renderTableBody = () => {
    if (isLoading) {
      return renderLoading()
    }
    if (R.isEmpty(apiTokens)) {
      return renderNoToken()
    }
    return R.map(
      (apiToken: ApiToken) => renderTokenRow(apiToken),
      R.sortBy(R.prop('createdAt'))(apiTokens)
    )
  }

  return (
    <Table id="api-token-table" fixed compact="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="name" content="Token Name" width={3} />
          <Table.HeaderCell className="created-at" content="Created At" width={2} />
          <Table.HeaderCell className="actions" width={1} />
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderTableBody()}</Table.Body>
    </Table>
  )
}

export default ApiTokenTable
