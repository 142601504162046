/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type ChangePasswordState = {
  changedWithSuccess: boolean
  waitingServerResponse: boolean
  error: string | null
}

export const initialState: ChangePasswordState = {
  changedWithSuccess: false,
  waitingServerResponse: false,
  error: null
}

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    init: (state: ChangePasswordState) => initialState,
    changePassword: (
      state: ChangePasswordState,
      action: PayloadAction<{ currentPassword: string; newPassword: string }>
    ) => {
      state.changedWithSuccess = false
      state.waitingServerResponse = true
      state.error = null
    },
    changePasswordSucceeded: (state: ChangePasswordState) => {
      state.changedWithSuccess = true
      state.waitingServerResponse = false
    },
    changePasswordFailed: (state: ChangePasswordState, action: PayloadAction<ErrorType>) => {
      state.waitingServerResponse = false
      state.error = action.payload.message
    }
  }
})

export const { init, changePassword, changePasswordSucceeded, changePasswordFailed } =
  changePasswordSlice.actions
export default changePasswordSlice.reducer
