import * as R from 'ramda'
import React from 'react'
import { Message } from 'semantic-ui-react'

type Message = {
  type: string | null
  header: string
  content: string
}

type Props = {
  messages?: Array<Message>
  dismiss: (idx: number) => void
}
const Messages = (props: Props): JSX.Element => {
  const { dismiss, messages } = props
  const handleDismiss = (idx: number) => () => {
    dismiss(idx)
  }
  return (
    <div>
      {R.addIndex<Message, JSX.Element>(R.map)((message: Message, idx: number) => (
        <Message
          key={idx}
          onDismiss={handleDismiss(idx)}
          error={message.type === 'error'}
          success={message.type === 'success'}
        >
          {message.header && <Message.Header>{message.header}</Message.Header>}
          <Message.Content>{message.content}</Message.Content>
        </Message>
      ))(messages ?? [])}
    </div>
  )
}

Messages.defaultProps = {
  messages: []
}

export default Messages
