import * as R from 'ramda'
import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, Input, Popup, Table } from 'semantic-ui-react'
import Clipboard from 'clipboard'

import type { Cluster, Service } from '../../ducks/cluster'
import './ClusterRow.css'

type Props = {
  idx: number
  cluster: Cluster
  showAlerts: (clusterName: string) => void
  showDeleteConfirmation: (clusterName: string) => void
}

class ClusterRow extends React.Component<Props> {
  clipboard!: Clipboard

  componentDidMount(): void {
    this.clipboard = new Clipboard('.jupyter-token .copy-jupyter-token-button')
  }

  componentWillUnmount(): void {
    this.clipboard.destroy()
  }

  handleShowAlerts(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    const { cluster, showAlerts } = this.props
    event.preventDefault()
    event.stopPropagation()
    showAlerts(cluster.name)
  }

  handleClickLink(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    event.preventDefault()
    event.stopPropagation()
    const { cluster } = this.props
    window.open(this.getJupyterNotebookUrl(), `${cluster.name}_jupyter`)
  }

  handleClickDelete(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    event.preventDefault()
    const { cluster, showDeleteConfirmation } = this.props
    showDeleteConfirmation(cluster.name)
  }

  getJupyterNotebookUrl(): string {
    return R.view(R.lensPath(['instances', 0, 'urlPath']), this.jupyterNotebook())
  }

  getJupyterToken(): string {
    return R.view(R.lensPath(['instances', 0, 'jupyterToken']), this.jupyterNotebook())
  }

  jupyterNotebook(): Service | undefined {
    const { cluster } = this.props
    return R.find(R.propEq('name', 'jupyter-notebook'), cluster.services)
  }

  renderStatusCell(): JSX.Element {
    const { cluster, idx } = this.props
    const alerts = this.jupyterNotebook()?.alerts
    return (
      <Table.Cell className="status">
        <Icon name="circle" color={R.isEmpty(alerts) ? 'green' : 'red'} />
        <span id={`cluster-status-${idx}`}>{cluster.status}</span>
        {!R.isEmpty(alerts) && (
          <Button
            id={`alert-button-${idx}`}
            content="Alert"
            icon="exclamation circle"
            size="mini"
            compact
            negative
            onClick={(e) => this.handleShowAlerts(e)}
            className="inner-cell-button"
          />
        )}
      </Table.Cell>
    )
  }

  renderCreateAtCell(): JSX.Element {
    const { cluster } = this.props
    return (
      <Table.Cell className="created-at">
        {cluster.createdAt ? new Date(cluster.createdAt).toLocaleString() : '-'}
      </Table.Cell>
    )
  }

  renderAccessPointsCell(): JSX.Element {
    const { idx } = this.props
    return (
      <Table.Cell className="access-point">
        {this.getJupyterNotebookUrl() ? (
          <span>
            <Popup
              on="click"
              flowing
              trigger={
                <Button
                  id={`access-jupyter-button-${idx}`}
                  icon="paper plane outline"
                  compact
                  positive
                  size="mini"
                  content="Access Jupyter Notebook"
                  className="access-jupyter-button inner-cell-button"
                />
              }
            >
              <p>Use it for authentication of Jupyter Notebook</p>
              <Input
                id={`jupyter-token-${idx}`}
                className="jupyter-token"
                type="text"
                value={this.getJupyterToken()}
                readOnly
                labelPosition="right"
                label={
                  <Popup
                    trigger={
                      <Button
                        id={`copy-jupyter-token-${idx}`}
                        icon="copy outline"
                        className="copy-jupyter-token-button"
                        attached="right"
                        data-clipboard-target={`#jupyter-token-${idx}`}
                      />
                    }
                    inverted
                    size="mini"
                    content="Copy to Clipboard"
                    position="bottom left"
                  />
                }
              />
              <Button
                className="jupyter-open-button"
                icon="external"
                content="Open Jupyter Notebook"
                compact
                positive
                size="mini"
                onClick={(e) => this.handleClickLink(e)}
              />
            </Popup>
          </span>
        ) : (
          '-'
        )}
      </Table.Cell>
    )
  }

  renderAction(): JSX.Element {
    const { idx } = this.props
    return (
      <Table.Cell className="actions" textAlign="center">
        <Button
          id={`delete-cluster-button-${idx}`}
          icon="trash"
          content="Delete"
          className="delete"
          compact
          onClick={(e) => this.handleClickDelete(e)}
          size="mini"
        />
      </Table.Cell>
    )
  }

  render(): JSX.Element {
    const { cluster, idx } = this.props
    return (
      <Table.Row className="created">
        <Table.Cell className="cluster-name" id={`cluster-name-${idx}`}>
          <Link to={`/cluster-detail/${cluster.name}`}>{cluster.name}</Link>
        </Table.Cell>
        {this.renderStatusCell()}
        {this.renderCreateAtCell()}
        {this.renderAccessPointsCell()}
        {this.renderAction()}
      </Table.Row>
    )
  }
}

export default ClusterRow
