import React, { MouseEvent } from 'react'
import { Modal, Button, Popup, Input } from 'semantic-ui-react'
import Clipboard from 'clipboard'

import './ApiTokenCreateResultModal.css'

type Props = {
  isOpen: boolean
  createdToken: string
  createdTokenName: string
  close: () => void
}

class ApiTokenCreateResultModal extends React.Component<Props> {
  clipboard!: Clipboard

  componentDidMount(): void {
    this.clipboard = new Clipboard('#api-token-modal .copy-api-token-button')
  }

  componentWillUnmount(): void {
    this.clipboard.destroy()
  }

  handleClose(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    const { close } = this.props
    event.preventDefault()
    close()
  }

  renderTokenInput(): JSX.Element {
    const { createdToken } = this.props
    return (
      <Input
        id="new-api-token"
        type="text"
        value={createdToken}
        fluid
        readOnly
        labelPosition="right"
        label={
          <Popup
            trigger={
              <Button
                icon="copy outline"
                className="copy-api-token-button"
                attached="right"
                data-clipboard-target="#new-api-token"
              />
            }
            inverted
            size="mini"
            content="Copy this Token to Clipboard"
            position="top left"
          />
        }
      />
    )
  }

  renderContent(): JSX.Element {
    const { createdTokenName } = this.props
    return (
      <Modal.Content>
        <label htmlFor="token-field">
          New API Token:
          {createdTokenName}
        </label>
        {this.renderTokenInput()}
        <div className="alert-message">
          Make sure you copy this token now - Never displayed again.
        </div>
        If you lose it, you need to create a new one.
      </Modal.Content>
    )
  }

  render(): JSX.Element {
    const { isOpen } = this.props
    return (
      <Modal
        id="api-token-modal"
        closeOnDocumentClick={false}
        open={isOpen}
        onClose={(e) => this.handleClose(e)}
      >
        <Modal.Header>New API Token is created Successfully</Modal.Header>
        {this.renderContent()}
        <Modal.Actions>
          <Button
            id="close-api-token-create-result-modal"
            content="Close"
            onClick={(e) => this.handleClose(e)}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ApiTokenCreateResultModal
