import createDebug from 'debug'
import { createLogic } from 'redux-logic'
import type { Dispatch } from 'redux'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  fetchPerformanceTypeList,
  fetchPerformanceTypeListSucceeded,
  fetchPerformanceTypeListFailed
} from '../ducks/perf_type'
import { editCreateClusterInfo } from '../ducks/cluster_creation'
import type { PerformanceType } from '../ducks/perf_type'
import type { ErrorType } from '../types'
import type { LogicDependency, RootState } from '../redux/store'

const debug = createDebug('aapf:perf_types')

const fetchPerformanceTypeListLogic = createLogic<
  RootState,
  LogicDependency,
  string,
  PayloadAction
>({
  type: fetchPerformanceTypeList.type,
  latest: true,

  process({ webClient, apiURL }, dispatch: Dispatch, done: () => void): void {
    debug('fetch')
    const headers = { 'Content-Type': 'application/json' }

    webClient.get<{ perfTypes: Array<PerformanceType> }>(apiURL('perf-types'), headers).subscribe({
      next(payload: { perfTypes: Array<PerformanceType> }) {
        if (payload.perfTypes.length > 0) {
          // set default value to form
          const defaultPerfTypeName = payload.perfTypes[0].name
          dispatch(
            editCreateClusterInfo({
              field: 'jupyterPerfTypeName',
              value: defaultPerfTypeName
            })
          )
        }
        dispatch(fetchPerformanceTypeListSucceeded(payload))
        done()
      },
      error(error: ErrorType) {
        dispatch(fetchPerformanceTypeListFailed(error))
        done()
      }
    })
  }
})

export default fetchPerformanceTypeListLogic
