import { configureStore as confStore, EnhancedStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createLogicMiddleware } from 'redux-logic'
import { History } from 'history'
import { SchedulerLike } from 'rxjs'
import configureRootReducer from './reducer'
import rootLogic from './logic'
import WebClient from '../WebClient'

const webClient = new WebClient()

export type LogicDependency = {
  webClient: WebClient
  apiURL: (path: string) => string
  pollingInterval: number
  rxScheduler?: SchedulerLike
}

const logicContext: LogicDependency = {
  webClient,
  // eslint-disable-next-line @typescript-eslint/unbound-method
  apiURL: WebClient.apiURL,
  pollingInterval: 5000 // msec
}

export type RootState = ReturnType<ReturnType<typeof configureRootReducer>>

// Configure typings of RootState for react-redux
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/41031
declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends RootState {}
}

const logicMiddleware = createLogicMiddleware(rootLogic, logicContext)

export default function configureStore(
  history: History<unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  preloadedState?: any
): EnhancedStore {
  const store = confStore({
    reducer: configureRootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(routerMiddleware(history))
        .concat(logicMiddleware),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    preloadedState
  })

  return store
}
