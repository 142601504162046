import { loginLogic, loginResumeLogic, logoutLogic, autoLogoutLogic } from '../logic/auth'
import {
  pollClusterListLogic,
  refreshClusterListLogic,
  fetchClusterLogic,
  fetchClusterListLogic,
  createClusterLogic,
  deleteClusterLogic
} from '../logic/cluster'
import fetchImageListLogic from '../logic/image'
import fetchPerformanceTypeListLogic from '../logic/perf_type'
import {
  fetchApiTokenListLogic,
  createApiTokenLogic,
  revokeApiTokenLogic
} from '../logic/api_token'
import changePasswordLogic from '../logic/change_password'

const rootLogic = [
  loginLogic,
  loginResumeLogic,
  logoutLogic,
  autoLogoutLogic,
  pollClusterListLogic,
  refreshClusterListLogic,
  fetchClusterLogic,
  fetchClusterListLogic,
  createClusterLogic,
  deleteClusterLogic,
  fetchImageListLogic,
  fetchPerformanceTypeListLogic,
  fetchApiTokenListLogic,
  createApiTokenLogic,
  revokeApiTokenLogic,
  changePasswordLogic
]

export default rootLogic
