import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ApiTokenCreateModalComponent from '../../components/ApiToken/ApiTokenCreateModal'
import {
  createApiToken as createApiTokenAction,
  closeCreateApiTokenModal,
  init as initializeApiTokensCreate
} from '../../ducks/api_token_create'
import { init as initializeApiTokensRevoke } from '../../ducks/api_token_revoke'
import type { ApiTokensInfoCreate } from '../../ducks/api_token_create'

type Props = {
  initialize: () => void
  isOpen: boolean
  isExecuting: boolean
  errorMessage?: string
  createApiToken: (tokenName: string, password: string) => void
  closeApiTokenCreateModal: () => void
}

class ApiTokenCreateModal extends React.PureComponent<Props> {
  static defaultProps = {
    errorMessage: ''
  }

  componentDidMount() {
    const { initialize } = this.props
    initialize()
  }

  render() {
    const { isOpen, errorMessage, isExecuting, createApiToken, closeApiTokenCreateModal } =
      this.props
    return (
      <ApiTokenCreateModalComponent
        isOpen={isOpen}
        errorMessage={errorMessage}
        isExecuting={isExecuting}
        create={createApiToken}
        close={closeApiTokenCreateModal}
      />
    )
  }
}

const mapStateToProps = (state: { apiTokenCreate: ApiTokensInfoCreate }) => ({
  isOpen: state.apiTokenCreate.isOpenCreateModal,
  isExecuting: state.apiTokenCreate.isCreating,
  errorMessage: state.apiTokenCreate.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initialize() {
    dispatch(initializeApiTokensCreate())
  },
  createApiToken(tokenName: string, password: string) {
    dispatch(createApiTokenAction({ tokenName, password }))
    dispatch(initializeApiTokensRevoke())
  },
  closeApiTokenCreateModal() {
    dispatch(closeCreateApiTokenModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenCreateModal)
