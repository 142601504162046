import * as R from 'ramda'
import * as React from 'react'
import { connect } from 'react-redux'
import { Container, Message } from 'semantic-ui-react'
import type { Dispatch } from 'redux'
import ApiTokenMenuBar from './ApiTokenMenuBar'
import ApiTokenTable from './ApiTokenTable'
import ApiTokenCreateModal from './ApiTokenCreateModal'
import ApiTokenCreateResultModal from './ApiTokenCreateResultModal'
import ApiTokenRevokeModal from './ApiTokenRevokeModal'
import { fetchApiTokenList, init as initializeApiTokensFetch } from '../../ducks/api_token_fetch'
import { init as initializeApiTokensCreate } from '../../ducks/api_token_create'
import { init as initializeApiTokensRevoke } from '../../ducks/api_token_revoke'
import type { ApiTokensInfoFetch } from '../../ducks/api_token_fetch'
import type { ApiTokensInfoRevoke } from '../../ducks/api_token_revoke'

type Props = {
  fetchingTableErrorMessage?: string
  revokingErrorMessage?: string
  initialize: () => void
}

class ApiTokenApp extends React.Component<Props> {
  static defaultProps = {
    fetchingTableErrorMessage: '',
    revokingErrorMessage: ''
  }

  componentDidMount() {
    const { initialize } = this.props
    initialize()
  }

  renderErrorMessage() {
    const { fetchingTableErrorMessage, revokingErrorMessage } = this.props
    return R.isEmpty(fetchingTableErrorMessage) && R.isEmpty(revokingErrorMessage) ? null : (
      <Message negative>
        {R.isEmpty(fetchingTableErrorMessage) ? null : <div>{fetchingTableErrorMessage}</div>}
        {R.isEmpty(revokingErrorMessage) ? null : <div>{revokingErrorMessage}</div>}
      </Message>
    )
  }

  render() {
    return (
      <Container>
        {this.renderErrorMessage()}
        <ApiTokenMenuBar />
        <ApiTokenTable />
        <ApiTokenCreateModal />
        <ApiTokenCreateResultModal />
        <ApiTokenRevokeModal />
      </Container>
    )
  }
}

const mapStateToProps = (state: {
  apiTokenFetch: ApiTokensInfoFetch
  apiTokenRevoke: ApiTokensInfoRevoke
}) => ({
  fetchingTableErrorMessage: state.apiTokenFetch.errorMessage,
  revokingErrorMessage: state.apiTokenRevoke.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initialize() {
    dispatch(initializeApiTokensFetch())
    dispatch(initializeApiTokensCreate())
    dispatch(initializeApiTokensRevoke())
    dispatch(fetchApiTokenList())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenApp)
