/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type ApiTokensInfoRevoke = {
  isOpen: boolean
  revokingTokenName: string
  errorMessage: string
}

export const initialState: ApiTokensInfoRevoke = {
  isOpen: false,
  revokingTokenName: '',
  errorMessage: ''
}

export const apiTokenFetchSlice = createSlice({
  name: 'api_token_revoke',
  initialState,
  reducers: {
    init: (state: ApiTokensInfoRevoke) => initialState,
    openRevokeApiTokenModal: (state: ApiTokensInfoRevoke, action: PayloadAction<string>) => {
      state.isOpen = true
      state.revokingTokenName = action.payload
    },
    closeRevokeApiTokenModal: (state: ApiTokensInfoRevoke) => {
      state.isOpen = false
      state.revokingTokenName = ''
    },
    revokeApiToken: (state: ApiTokensInfoRevoke, action: PayloadAction<string>) => {
      state.isOpen = false
      state.revokingTokenName = action.payload
      state.errorMessage = ''
    },
    revokeApiTokenSucceed: (state: ApiTokensInfoRevoke) => {
      state.revokingTokenName = ''
      state.isOpen = false
    },
    revokeApiTokenFailed: (state: ApiTokensInfoRevoke, action: PayloadAction<ErrorType>) => {
      state.errorMessage = action.payload.message
    }
  }
})

export const {
  init,
  openRevokeApiTokenModal,
  closeRevokeApiTokenModal,
  revokeApiToken,
  revokeApiTokenSucceed,
  revokeApiTokenFailed
} = apiTokenFetchSlice.actions
export default apiTokenFetchSlice.reducer
