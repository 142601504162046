import { connect } from 'react-redux'
import React from 'react'
import * as R from 'ramda'
import type { Dispatch } from 'redux'
import { dismissMessage } from '../../ducks/cluster'
import type { ClusterState, Message } from '../../ducks/cluster'

import MessagesComponent from '../../components/common/Messages'

type Props = {
  messages: Message[]
  dismiss: (idx: number) => void
}
const ClusterMessages = ({ messages, dismiss }: Props) => (
  <MessagesComponent
    messages={R.map(
      (msg) => ({
        type: msg.type,
        header: 'Error',
        content: msg.message
      }),
      messages
    )}
    dismiss={dismiss}
  />
)

const mapStateToProps = (state: { cluster: ClusterState }) => ({
  messages: state.cluster.messages
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dismiss(idx: number) {
    dispatch(dismissMessage(idx))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterMessages)
