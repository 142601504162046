import React, { ChangeEvent, MouseEvent } from 'react'
import { Modal, Button, Input, Message, Icon } from 'semantic-ui-react'

type Props = {
  isOpen: boolean
  errorMessage?: string
  isExecuting: boolean
  create: (tokenName: string, password: string) => void
  close: () => void
}

type State = {
  tokenName: string
  password: string
}

class ApiTokenCreateModal extends React.Component<Props, State> {
  static defaultProps = {
    errorMessage: ''
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      tokenName: '',
      password: ''
    }
  }

  static getDerivedStateFromProps(props: Props): State | null {
    if (props.isOpen) {
      return null
    }
    return { tokenName: '', password: '' }
  }

  handleCreate(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    const { tokenName, password } = this.state
    const { create } = this.props
    event.preventDefault()
    create(tokenName, password)
  }

  handleClose(event: MouseEvent<HTMLElement, globalThis.MouseEvent>): void {
    const { isExecuting, close } = this.props
    event.preventDefault()
    if (!isExecuting) {
      close()
      this.setState({ tokenName: '', password: '' })
    }
  }

  handleChangeTokenName(event: ChangeEvent<HTMLInputElement>, data: { value: string }): void {
    event.preventDefault()
    this.setState({ tokenName: data.value })
  }

  handleChangePassword(event: ChangeEvent<HTMLInputElement>, data: { value: string }): void {
    event.preventDefault()
    this.setState({ password: data.value })
  }

  checkFormIsEmpty(): boolean {
    const { tokenName, password } = this.state
    return !(!!tokenName && !!password)
  }

  renderMessage(): JSX.Element | null {
    const { isExecuting, errorMessage } = this.props
    if (isExecuting) {
      return (
        <Message>
          <Icon loading name="spinner" />
          Creating API Token...
        </Message>
      )
    }
    return errorMessage ? (
      <Message id="create-api-token-error-message" negative>
        <Message.Header>Failed</Message.Header>
        {errorMessage}
      </Message>
    ) : null
  }

  renderContent(): JSX.Element {
    const { isExecuting } = this.props
    return (
      <Modal.Content>
        <label htmlFor="token-name-field">
          Token Name
          <Input
            name="token-name-field"
            fluid
            onChange={(event, data) => this.handleChangeTokenName(event, data)}
            disabled={isExecuting}
          />
        </label>
        <br />
        <label htmlFor="input-password-field">
          Input your login password to authenticate.
          <Input
            name="password-field"
            fluid
            input="password"
            onChange={(event, data) => this.handleChangePassword(event, data)}
            disabled={isExecuting}
          />
        </label>
        {this.renderMessage()}
      </Modal.Content>
    )
  }

  renderAction(): JSX.Element {
    const { isExecuting } = this.props
    return (
      <Modal.Actions>
        <Button
          id="create-api-token-button"
          content="Create API Token"
          primary
          disabled={this.checkFormIsEmpty() || isExecuting}
          onClick={(event) => this.handleCreate(event)}
        />
        <Button
          content="Cancel"
          disabled={isExecuting}
          onClick={(event) => this.handleClose(event)}
        />
      </Modal.Actions>
    )
  }

  render(): JSX.Element {
    const { isOpen } = this.props
    return (
      <Modal closeOnDimmerClick={false} open={isOpen} onClose={(e) => this.handleClose(e)}>
        <Modal.Header>Create API Token</Modal.Header>
        {this.renderContent()}
        {this.renderAction()}
      </Modal>
    )
  }
}

export default ApiTokenCreateModal
