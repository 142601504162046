import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import Login from '../../components/Login/Login'
import { login } from '../../ducks/auth'
import type { AuthState } from '../../ducks/auth'

type Props = {
  authenticating: boolean
  error: string | null
  onLogin: (username: string, password: string) => void
}

class LoginApp extends React.PureComponent<Props> {
  login?: Login

  componentDidMount() {
    this.login?.focus()
  }

  componentDidUpdate() {
    const { error } = this.props
    if (error) {
      this.login?.selectPassword()
    }
  }

  render() {
    const { authenticating, error, onLogin } = this.props
    return (
      <Login
        ref={(elem) => {
          this.login = elem ?? undefined
        }}
        authenticating={authenticating}
        error={error}
        onLogin={onLogin}
      />
    )
  }
}

const mapStateToProps = (state: { auth: AuthState }) => ({
  authenticating: state.auth.authenticating,
  error: state.auth.error
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin(username: string, password: string) {
    dispatch(login({ username, password }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginApp)
