/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

export type ServiceResources = {
  cpu: number | null
  memory: number | null
  'nvidia.com/gpu': number | null
}

export type PerformanceType = {
  name: string
  displayName: string
  resources: ServiceResources
}

export type PerfTypeState = {
  isFetching: boolean
  error: string
  perfTypes: Array<PerformanceType>
}

export const initialState: PerfTypeState = {
  isFetching: false,
  error: '',
  perfTypes: []
}

export const perfTypeSlice = createSlice({
  name: 'perf_type',
  initialState,
  reducers: {
    init: (state: PerfTypeState) => initialState,
    fetchPerformanceTypeList: (state: PerfTypeState) => {
      state.isFetching = true
    },
    fetchPerformanceTypeListSucceeded: (
      state: PerfTypeState,
      action: PayloadAction<{ perfTypes: Array<PerformanceType> }>
    ) => {
      state.isFetching = false
      state.perfTypes = action.payload.perfTypes
    },
    fetchPerformanceTypeListFailed: (state: PerfTypeState, action: PayloadAction<ErrorType>) => {
      state.isFetching = false
      state.error = action.payload.message
    }
  }
})

export const {
  init,
  fetchPerformanceTypeList,
  fetchPerformanceTypeListSucceeded,
  fetchPerformanceTypeListFailed
} = perfTypeSlice.actions
export default perfTypeSlice.reducer
