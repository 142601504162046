import * as R from 'ramda'
import React from 'react'
import { Container, Header, Icon, Message, List, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ClusterImageDescription from './ClusterImageDescription'
import ClusterPerformance from './ClusterPerformance'

import type { Cluster, JupyterNotebookService, Service } from '../../ducks/cluster'
import type { Image } from '../../ducks/image'
import type { PerformanceType, ServiceResources } from '../../ducks/perf_type'

type Props = {
  cluster: Cluster
  isLoading: boolean
  error?: string
}

const ClusterDetailPage = (props: Props): JSX.Element => {
  const { cluster, isLoading, error } = props

  const renderService = (
    serviceName: string,
    serviceDisplayName: string,
    image: Image | null,
    perfType: PerformanceType | null
  ) => {
    if (!perfType || !image) return null
    return (
      <Segment key={`service-${serviceName}`} id={`service-${serviceName}`}>
        <Header as="h3">{serviceDisplayName}</Header>
        <Header as="h5">{`Image: ${image.displayName}`}</Header>
        <ClusterImageDescription description={image.description} />
        <Header as="h5">{`Performance Type: ${perfType.displayName}`}</Header>
        <ClusterPerformance resources={perfType.resources} />
      </Segment>
    )
  }

  const sumResourceByPath = (path: Array<string>, services: Array<Service>) => {
    const lists = R.map((service) => {
      const v = R.view(R.lensPath<Service, number>(path), service)
      return v ? v * service.numberOfInstances : null
    }, services)

    return R.all(R.isNil)(lists) ? null : R.sum(lists as number[])
  }

  const renderDetail = () => {
    const jupyter: JupyterNotebookService = R.find(
      R.propEq('name', 'jupyter-notebook'),
      cluster.services
    ) as JupyterNotebookService

    const totalRequests: ServiceResources = {
      cpu: null,
      memory: null,
      'nvidia.com/gpu': null
    }
    totalRequests.cpu = sumResourceByPath(['perfType', 'resources', 'cpu'], cluster.services)
    totalRequests.memory = sumResourceByPath(['perfType', 'resources', 'memory'], cluster.services)
    totalRequests['nvidia.com/gpu'] = sumResourceByPath(
      ['perfType', 'resources', 'nvidia.com/gpu'],
      cluster.services
    )

    return (
      <Container>
        <Header as="h2" key="total-resource-usage">
          Total Resource Usage
        </Header>
        <ClusterPerformance resources={totalRequests} />
        <Header as="h2" key="detail-information">
          Detail Information
        </Header>
        {renderService('jupyter-notebook', 'Jupyter Notebook', jupyter.image, jupyter.perfType)}
      </Container>
    )
  }

  const renderLoading = () => (
    <Message>
      <Icon loading name="spinner" />
      Loading...
    </Message>
  )

  return (
    <Container>
      <Link to="/">&lt; Back to Top</Link>
      <Header as="h1">{cluster.name}</Header>
      {error && (
        <Message error>
          <List>
            <List.Header>Error</List.Header>
            <List.Item content={error} />
          </List>
        </Message>
      )}
      {isLoading ? renderLoading() : renderDetail()}
    </Container>
  )
}

ClusterDetailPage.defaultProps = {
  error: null
}

export default ClusterDetailPage
