import * as React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import ApiTokenRevokeModalComponent from '../../components/ApiToken/ApiTokenRevokeModal'
import {
  revokeApiToken as revokeApiTokenOnModalAction,
  closeRevokeApiTokenModal
} from '../../ducks/api_token_revoke'
import { revokeApiToken as revokeApiTokenOnFetch } from '../../ducks/api_token_fetch'
import type { ApiTokensInfoRevoke } from '../../ducks/api_token_revoke'

type Props = {
  isOpen: boolean
  tokenName: string
  revokeApiToken: (tokenName: string) => void
  closeApiTokenRevokeModal: () => void
}

const ApiTokenRevokeModal = ({
  isOpen,
  tokenName,
  revokeApiToken,
  closeApiTokenRevokeModal
}: Props) => (
  <ApiTokenRevokeModalComponent
    isOpen={isOpen}
    tokenName={tokenName}
    revoke={revokeApiToken}
    close={closeApiTokenRevokeModal}
  />
)

const mapStateToProps = (state: { apiTokenRevoke: ApiTokensInfoRevoke }) => ({
  isOpen: state.apiTokenRevoke.isOpen,
  tokenName: state.apiTokenRevoke.revokingTokenName
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  revokeApiToken(tokenName: string) {
    dispatch(revokeApiTokenOnModalAction(tokenName))
    dispatch(revokeApiTokenOnFetch(tokenName))
  },
  closeApiTokenRevokeModal() {
    dispatch(closeRevokeApiTokenModal())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenRevokeModal)
