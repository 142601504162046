import { replace } from 'connected-react-router'
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { AuthState } from '../ducks/auth'

const locationHelper = locationHelperBuilder({})

/**
 * High Order Component to add check and redirect mechanism to
 * 'authentication required' component.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withAuth = connectedReduxRedirect<any, { auth: AuthState }>({
  redirectPath: '/login',
  authenticatedSelector: (state) => !!state.auth.username,
  authenticatingSelector: (state) => state.auth.authenticating,
  redirectAction: replace,
  wrapperDisplayName: 'UserIsAuthenticated'
})

/**
 * High Order Component to add check and redirect mechanism to
 * 'no authentication required' component.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withoutAuth = connectedReduxRedirect<any, { auth: AuthState }>({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  authenticatedSelector: (state) => !state.auth.username,
  authenticatingSelector: (state) => state.auth.authenticating,
  redirectAction: replace,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false
})
