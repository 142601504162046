/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from 'ramda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorType } from '../types'

type CreatedTokenType = {
  tokenName: string
  token: string
  createdAt: string
}

export type ApiTokensInfoCreate = {
  isOpenCreateModal: boolean

  isCreating: boolean
  errorMessage: string
  createdTokenInfo: CreatedTokenType

  isOpenCreateResultModal: boolean
}

export const initialState: ApiTokensInfoCreate = {
  isOpenCreateModal: false,

  isCreating: false,
  errorMessage: '',
  createdTokenInfo: {
    tokenName: '',
    token: '',
    createdAt: ''
  },

  isOpenCreateResultModal: false
}

export const apiTokenCreateSlice = createSlice({
  name: 'api_token_create',
  initialState,
  reducers: {
    init: (state: ApiTokensInfoCreate) => initialState,
    openCreateApiTokenModal: (state: ApiTokensInfoCreate) => {
      state.isOpenCreateModal = true
    },
    closeCreateApiTokenModal: (state: ApiTokensInfoCreate) => {
      state.isOpenCreateModal = false
      state.errorMessage = ''
    },
    createApiToken: (
      state: ApiTokensInfoCreate,
      action: PayloadAction<{ tokenName: string; password: string }>
    ) => {
      state.isCreating = true
    },
    createApiTokenSucceed: (
      state: ApiTokensInfoCreate,
      action: PayloadAction<CreatedTokenType>
    ) => {
      state.isOpenCreateModal = false
      state.isOpenCreateResultModal = true
      state.errorMessage = ''
      state.isCreating = false
      state.createdTokenInfo = action.payload
    },
    createApiTokenFailed: (state: ApiTokensInfoCreate, action: PayloadAction<ErrorType>) => {
      state.isCreating = false
      state.errorMessage = action.payload.message
    },
    closeCreateResultModal: (state: ApiTokensInfoCreate) => {
      state.createdTokenInfo = {
        tokenName: '',
        token: '',
        createdAt: ''
      }
      state.isOpenCreateResultModal = false
    }
  }
})

export const {
  init,
  openCreateApiTokenModal,
  closeCreateApiTokenModal,
  createApiToken,
  createApiTokenSucceed,
  createApiTokenFailed,
  closeCreateResultModal
} = apiTokenCreateSlice.actions
export default apiTokenCreateSlice.reducer
