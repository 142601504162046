import React, { MouseEvent } from 'react'
import { Button, Menu } from 'semantic-ui-react'
import './ClusterMenuBar.css'

export type Props = {
  refreshing?: boolean
  refresh: () => void
  createCluster: () => void
}

const ClusterMenuBar = ({ refreshing, refresh, createCluster }: Props): JSX.Element => {
  const handleRefresh = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    refresh()
  }

  const handleCreateCluster = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    event.preventDefault()
    createCluster()
  }

  return (
    <Menu className="menu-bar" secondary size="mini">
      <Menu.Menu position="right">
        <Button
          id="create-cluster-nav-button"
          icon="plus"
          content="Create AACluster"
          primary
          size="small"
          compact
          onClick={handleCreateCluster}
        />
        <Button
          id="refresh-clusters"
          icon="refresh"
          content="Refresh"
          basic
          color="black"
          size="small"
          compact
          onClick={handleRefresh}
          disabled={refreshing}
        />
      </Menu.Menu>
    </Menu>
  )
}

ClusterMenuBar.defaultProps = {
  refreshing: false
}

export default ClusterMenuBar
