import React, { Component } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import {
  createCluster,
  editCreateClusterInfo,
  init as initializeClusterCreationAction
} from '../../ducks/cluster_creation'
import {
  fetchImageList as fetchImageListAction,
  init as initializeImagesAction
} from '../../ducks/image'
import {
  fetchPerformanceTypeList as fetchPerformanceTypeListAction,
  init as initializePerfTypeAction
} from '../../ducks/perf_type'
import ClusterCreatePage from '../../components/Cluster/ClusterCreatePage'
import type { ClusterInfo } from '../../components/Cluster/ClusterCreatePage'
import type { ClusterCreationState, CreateClusterParams } from '../../ducks/cluster_creation'
import type { PerfTypeState, PerformanceType } from '../../ducks/perf_type'
import type { ImageState, Image } from '../../ducks/image'
import { ErrorType } from '../../types'

type State = {
  clusterCreation: ClusterCreationState
  image: ImageState
  perfType: PerfTypeState
}

type Props = {
  createClusterInfo: ClusterInfo
  isFetchingImages: boolean
  isFetchingPerformanceTypes: boolean
  fetchImagesError: string
  fetchPerformanceTypesError: string
  isValidating: boolean
  createClusterError: ErrorType | null
  create: (clusterInfo: CreateClusterParams) => void
  editClusterInfo: (fieldName: string, value: string) => void
  images: Array<Image>
  scrollToTop: boolean
  perfTypes: Array<PerformanceType>
  fetchImageList: () => void
  fetchPerformanceTypeList: () => void
  initializeImages: () => void
  initializePerfTypes: () => void
  initializeCreateClusterInfo: () => void
}

class ClusterCreateApp extends Component<Props> {
  static defaultProps = {
    createClusterError: null
  }

  componentDidMount() {
    const { fetchImageList, fetchPerformanceTypeList } = this.props
    fetchImageList()
    fetchPerformanceTypeList()
  }

  componentDidUpdate() {
    const { scrollToTop } = this.props
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }

  componentWillUnmount() {
    const { initializeCreateClusterInfo, initializeImages, initializePerfTypes } = this.props
    initializeImages()
    initializeCreateClusterInfo()
    initializePerfTypes()
  }

  render() {
    const {
      createClusterInfo,
      isFetchingImages,
      isFetchingPerformanceTypes,
      fetchImagesError,
      fetchPerformanceTypesError,
      isValidating,
      createClusterError,
      create,
      editClusterInfo,
      perfTypes,
      images
    } = this.props
    return (
      <ClusterCreatePage
        clusterInfo={createClusterInfo}
        isFetchingData={isFetchingImages && isFetchingPerformanceTypes}
        fetchDataError={R.defaultTo(fetchImagesError, fetchPerformanceTypesError)}
        isValidating={isValidating}
        createClusterError={createClusterError}
        create={create}
        editClusterInfo={editClusterInfo}
        perfTypes={perfTypes}
        images={images}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  createClusterInfo: state.clusterCreation.createClusterInfo,
  isFetchingImages: state.image.isFetching,
  isFetchingPerformanceTypes: state.perfType.isFetching,
  fetchImagesError: state.image.error,
  fetchPerformanceTypesError: state.perfType.error,
  isValidating: state.clusterCreation.isValidating,
  createClusterError: state.clusterCreation.error,
  scrollToTop: state.clusterCreation.scrollToTop,
  images: state.image.images,
  perfTypes: state.perfType.perfTypes
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  create(clusterInfo: CreateClusterParams) {
    dispatch(createCluster(clusterInfo))
  },
  fetchImageList() {
    dispatch(fetchImageListAction())
  },
  fetchPerformanceTypeList() {
    dispatch(fetchPerformanceTypeListAction())
  },
  editClusterInfo(fieldName: string, value: string) {
    dispatch(editCreateClusterInfo({ field: fieldName, value }))
  },
  initializeCreateClusterInfo() {
    dispatch(initializeClusterCreationAction())
  },
  initializeImages() {
    dispatch(initializeImagesAction())
  },
  initializePerfTypes() {
    dispatch(initializePerfTypeAction())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ClusterCreateApp)
